import axios, { AxiosResponse } from "axios";
import { jobAdStatusType } from "./interface";

const developerToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3NMZXZlbCI6ImRldmVsb3BlciJ9.94708dCFQO0Skn2TuUIeINK_5Nogh036UxDKZTwABiI";

const jobService = {
  getJobsByCompanyId: (companyId: string = "952193b0-8c9a-11eb-9fa7-ff20c3068f5b") => {
    return new Promise<AxiosResponse>((resolve, reject) =>
      axios
        .get(`job-ad/ads-with-company-id/?companyId=${companyId}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((resp: AxiosResponse) => resolve(resp))
        .catch((e) => reject(e))
    );
  },
  getJobAdsOfCurrentAccount: () => {
    return new Promise<AxiosResponse>((resolve, reject) =>
      axios
        .get(`company/ads`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((resp: AxiosResponse) => resolve(resp))
        .catch((e) => reject(e))
    );
  },
  getApplicantsOfJobAd: (jobAdId: string) => {
    return new Promise<AxiosResponse>((resolve, reject) =>
      axios
        .get(`job-ad/applicants?jobAdId=${jobAdId}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((resp: AxiosResponse) => resolve(resp))
        .catch((e) => reject(e))
    );
  },
  getJobById: (jobAdId: string) => {
    return new Promise<AxiosResponse>((resolve, reject) =>
      axios
        .get(`job-ad/${jobAdId}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((resp: AxiosResponse) => resolve(resp))
        .catch((e) => reject(e))
    );
  },
  createJobAd: (jobAd: any) => {
    jobAd.status = jobAdStatusType.DRAFT;
    return new Promise<AxiosResponse<string>>((resolve, reject) =>
      axios
        .post(`job-ad/dev-createAd`, JSON.stringify(jobAd) , {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "authorization": `Bearer ${developerToken}`
          },
        })
        .then((resp: AxiosResponse) => resolve(resp))
        .catch((e) => reject(e))
    );
  },
  updateJobAd: (id: string, changes: any) => {
    
    return new Promise<AxiosResponse>((resolve, reject) =>
      axios
        .patch(`job-ad/${ id }`, changes, {
          headers: {
            "Accept": "application/json"
          },
        })
        .then((resp: AxiosResponse) => resolve(resp))
        .catch((e) => reject(e))
    );
  },
  // should encapsulate to avoid wrong usage
  updateJobAdStatus: (jobAdId: string, status: jobAdStatusType) => {
    return new Promise<AxiosResponse>((resolve, reject) =>
      axios
        .patch(`job-ad/editStatus/${ jobAdId }`, {
          status: status
        }, {
          headers: {
            "Accept": "application/json"
          },
        })
        .then((resp: AxiosResponse) => resolve(resp))
        .catch((e) => reject(e))
    );
  },
  removeJobAd: (id: string) => jobService.updateJobAdStatus(id, jobAdStatusType.ARCHIVED),
  publishJobAd: (id: string) => jobService.updateJobAdStatus(id, jobAdStatusType.PUBLISHED),
  unPublishJobAd: (id: string) => jobService.updateJobAdStatus(id, jobAdStatusType.DRAFT),
  closeJobAd: (id: string) => jobService.updateJobAdStatus(id, jobAdStatusType.CLOSED),
  setJobAdAsTemplate: (id: string) => jobService.updateJobAdStatus(id, jobAdStatusType.TEMPLATE),

  updateJobAdBackgroundImage: (image: any) => {
    return new Promise<AxiosResponse>((resolve, reject) =>
      axios
        .patch(`job-ad/uploadFile`, image, {
          headers: {
            "Accept": "application/json",
            "authorization": `Bearer ${developerToken}`
          },
        })
        .then((resp: AxiosResponse) => resolve(resp))
        .catch((e) => reject(e))
    );
  }
};

export default jobService;
