import React, { Component } from 'react';
import useToken from '../../hooks/useToken';
import useAuth from '../../hooks/useAuth';
import authService from '../../services/auth';
import LanguageBar from 'pages/app-config/language-bar';
import Strings from 'localization/translation';

function MainHeader () {
    const { isLoggedIn } = useToken();
    const { account } = useAuth();

    const logout = () => {
        authService.logout(() => {
            console.log("USER_LOGGED_OUT");
        })
    }
        return (
            <>
                <header className="header-area header-desktop">
                    <div className="header-menu-wrapper">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="menu-full-width">
                                        <div className="logo">
                                            <a href="/"><img style={{ width: 150 }} src="/assets/images/logo2.png" alt="logo" /></a>
                                        </div>
                                        <div className="main-menu-content">
                                            <nav>
                                                <ul>
                                                    <li>
                                                        <a href="/jobs">{ Strings.jobs } </a>
                                                    </li>
                                                    <li>
                                                        <a href="/about"> { Strings.AboutCompany } </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                        <div className="logo-right-content">
                                            <div className="header-action-button d-flex align-items-center">
                                                <div className="notification-wrap d-flex align-items-center" style={{ display: 'none' }}>
                                                    <div className="notification-item mr-3">
                                                       <LanguageBar></LanguageBar>
                                                    </div>
                                                </div>
                                                {

                                                 isLoggedIn() && <div className="user-action-wrap">
                                                    <div className="notification-item">
                                            
                                                        <div className="dropdown">
                                                            <button className="notification-btn dropdown-toggle" type="button" id="userDropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <img src={ `${account?.company?.logoUrl}` } alt={ account?.company?.name } style={{ borderRadius: "0px"}} 
                                                                onError={($event) => {
                                                                    $event.currentTarget.onerror = null;
                                                                    $event.target.onerror = null;
                                                                    $event.currentTarget.src = "/assets/images/dots.png"
                                                                }}
                                                                />
                                                            </button>
                                                       
                                                            <div className="dropdown-menu" aria-labelledby="userDropdownMenu">
                                                                <div className="mess-dropdown">
                                                                    <div className="mess__title d-flex align-items-center">
                                                                        <div className="image dot-status online-status">
                                                                            <a href="#">
                                                                                <img src={ `${account?.company?.logoUrl}` } alt={ account?.company?.name } style={{ borderRadius: "0px"}} 
                                                                                    onError={($event) => {
                                                                                        $event.currentTarget.onerror = null;
                                                                                        $event.target.onerror = null;
                                                                                        $event.currentTarget.src = "/assets/images/dots.png"
                                                                                    }}
                                                                                />
                                                                            </a>
                                                                        </div>
                                                                        <div className="content">
                                                                            <h4 className="widget-title font-size-16">
                                                                                <a href="#">{ account?.company?.name }</a>
                                                                            </h4>
                                                                            <span className="email">{ account?.email }</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mess__body">
                                                                        <ul className="list-items">
                                                                            <li className="mb-0">
                                                                                    <a href="/company" className="d-block">
                                                                                        <i className="la la-gear"></i> { Strings.accountMenu.editCompanyInfo }
                                                                                    </a>
                                                                            </li>
                                                                            <li className="mb-0">
                                                                                    <a href="/" className="d-block">
                                                                                        <i className="la la-question"></i> { Strings.accountMenu.help }
                                                                                    </a>
                                                                            </li>
                                                                        
                                                                            <li className="mb-0">
                                                                                    <div className="section-block mt-2 mb-2"></div>
                                                                            </li>
                                                                            <li className="mb-0">
                                                                                    <a href="#!" className="d-block" onClick={logout}>
                                                                                        <i className="la la-power-off"></i> { Strings.accountMenu.logout }
                                                                                    </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                          
                                            </div>
                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                 </header>
            </>
        );
}
export default MainHeader;