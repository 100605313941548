import { WarningOutlined } from '@ant-design/icons';
import useAuth from 'hooks/useAuth';
import Strings from 'localization/translation';
import MainHeader from './PrivateHeader';



const MainLayout =({children}) => {
  const { account } = useAuth();
    return(
      <>
      {
      account.confirmedByCompany !== true &&
        <div  style={{ backgroundColor: "#ffeeba", width: "100%", zIndex: 100000, position: "fixed", top: "0px", textAlign: "center", color: "black"}}>
         <span>
           {
             Strings.errors.companyVerification
           }
         </span>
        </div>
      }
      <MainHeader></MainHeader>
      <section className="dashboard-area">
        <div className="dashboard-content-wrap" style={{paddingLeft: 0}}> 
          <div className="container-fluid">
            { children }
          </div>
        </div>
      </section>

    </>
    )
}

export default MainLayout;