import React from "react";
import { transformToErrorDataStructure } from "../transformsServerErrorMessage";

import {
  createErrorMessageElement,
  removeErrorMessageElement,
} from "./error-message-ui-element-actions";

function showValidationErrors(
  formRefList: React.RefObject<any>[] = [], //validating input fields of multiple forms
  originalErrors: any[] = [], //strucute [key1: {}, key2: {}],
  isTransformed: boolean = true
) {
  if (formRefList.length === 0) return false;

  let allFormElements: HTMLElement[] = [];
  formRefList.forEach((form: any) => {
    const formElements = Array.from(
      form?.current?.getElementsByClassName("show-error")
    ) as HTMLElement[];

    allFormElements.push(...formElements);
  });

  let errors = originalErrors;
  if (!isTransformed) errors = transformToErrorDataStructure(originalErrors);

  const elementsWithErrors = [];
  let index = 0;
  for (const element of allFormElements) {
    const elementName: any = element.getAttribute("data-name") || null;
    const uniqueId = `${elementName}-input-error`;
    if (elementName && errors[elementName]) {
      const message = errors[elementName].message;
      createErrorMessageElement(element, message, uniqueId);
      element.tabIndex=index;
      index++;
      elementsWithErrors.push(element);
    } else {
      removeErrorMessageElement(element, uniqueId);
    }
  }
  //focus on first element with errors
  if (elementsWithErrors.length > 0) {
    //window.location.hash = `${elementsWithErrors[0].id}`
    elementsWithErrors[0].focus();
    return true;
  }

  return false;
}

export { showValidationErrors };
