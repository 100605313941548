import jwtSign from "jwt-encode";
import { chatAPIkey, chatSignSecret } from "pages/chat/config";
import { useEffect, useState } from "react";
import { StreamChat } from "stream-chat";

const apiKey = chatAPIkey;

export const useClient = ({ currentAccount }) => {
  const [chatClient, setChatClient] = useState(null);

  const tokenOrProvider = jwtSign({
    user_id: currentAccount?.id
  }, chatSignSecret)

  useEffect(() => {
    const client = StreamChat.getInstance(apiKey);
    let didUserConnectInterrupt = false;

    const  { id, email, name, image} = currentAccount;
    const connectionPromise = client.connectUser({
      id: id, 
      username: email, 
      name: name,
      image: image
    }, tokenOrProvider).then(async() => {
      if (!didUserConnectInterrupt) 
      {
        setChatClient(client);
      }
    });

    return () => {
      didUserConnectInterrupt = true;
      setChatClient(null);

      connectionPromise
        .then(() => client.disconnectUser())
        .then(() => {
          console.log('connection closed');
        });
    };
  }, [apiKey, currentAccount.id, tokenOrProvider]);

  return {chatClient};
};
