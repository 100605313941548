import axios, { AxiosResponse } from "axios";
import { ICompanyAccount, ICredential } from "./interface";

const accountService = {
  createAccount: (data: ICompanyAccount) => {
    return new Promise<AxiosResponse>((resolve, reject) =>
      axios
        .post("account", JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((resp: AxiosResponse) => resolve(resp))
        .catch((e) => reject(e))
    );
  },
  login: (data: ICredential) => {
    return new Promise<AxiosResponse>((resolve, reject) =>
      axios
        .post("account/login", data, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((e) => reject(e))
    );
  },
  getAccount: () => {
    return new Promise<AxiosResponse>((resolve, reject) =>
      axios
        .get("account", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((resp) => {
          resolve(resp)
        })
        .catch((e) => reject(e))
    );
  },

  accountExists: (email: string) => {
    return new Promise<AxiosResponse>((resolve, reject) =>
      axios
        .post(`account/check-account-exists`, {
          email: email
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((resp) => {
          resolve(resp)
        })
        .catch((e) => reject(e))
    );
  },
  confirmEmail: (token: string, code: string) => {
    return new Promise<AxiosResponse>((resolve, reject) =>
      axios
        .post(`account/confirmEmail`, {
          code
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "authorization": `Bearer ${token}`
          },
        })
        .then((resp) => {
          resolve(resp)
        })
        .catch((e) => reject(e))
    );
  },
  resetPassword: (email) => {
    return new Promise<AxiosResponse>((resolve, reject) =>
      axios
        .get(`account/forgotPassword?email=${email}`,
        {
          headers: {
            "Content-Type": "application/json"
          },
        })
        .then((resp) => {
          resolve(resp)
        })
        .catch((e) => reject(e))
    );
  },
  saveNewPassword: (password, token) => {
    return new Promise<AxiosResponse>((resolve, reject) =>
      axios
        .post(`account/newPasswordFromLink`,
        { password },
        {
          headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
          },
        })
        .then((resp) => {
          resolve(resp)
        })
        .catch((e) => reject(e))
    );
  },
};

export default accountService;
