import LocalizedStrings from 'react-localization';

const en = require('./en.json');
const sv = require('./sv.json');
const de = require('./de.json');


const Strings = new LocalizedStrings({
  sv,
  en,
  de
});
export default Strings;
