type IDocumentProps = {
    documents: any[]
  };
  
  export const DocumentList: React.FC<IDocumentProps> = (props: IDocumentProps) => {
    if(!props.documents && props.documents.length <= 0) return <></>
    return (
      <>
      {
        props.documents?.map((doc:any) => {
              return (
                <div
                key={doc.id}
                style={{
                  backgroundColor: "grey",
                  borderRadius: 4,
                  margin: 10,
                  padding: "0.2em",
                  position: "relative",
                  cursor: "pointer"
                }}
              >
                <div
                  style={{
                    flexDirection: "row",
                    padding: 2,
                    paddingLeft: 5,
                    paddingRight: 5,
                  }}>
                  <div
                      style={{
                          color: "white",
                          textAlign: "center",
                          width: "auto",
                          fontSize: 12,
                      }}
                      >
                    <a href={`${doc.url}`} target="_blank" style={{color: "white"}} >{doc.name} </a>
                  </div>
                </div>
              </div>
              )
          })
      }
      {
          props.documents.length === 0 && (
              <div>No documents</div>
          )
      }
      </>
    );
  }
  