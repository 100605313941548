import { jobAdStatusType } from "../../../services/jobs/interface";

export default {
    properties: {
      id: {
        allowEmpty: false,
        required:true,
        type: 'string',
      },
      compnayId: {
        allowEmpty: false,
        required:true,
        type: 'string',
      },
      status: {
        allowEmpty: false,
        required:true,
        type: 'string',
        enum: [
            ...Object.values(jobAdStatusType)
        ]
      },
      name: {
        description: '',
        allowEmpty: false,
        type: 'string',
        required: false,
      },
      organisationNumber: {
        description: '',
        required:false,
        allowEmpty: false,
        type: 'string',
        pattern: /^\d{6}-\d{4}$/,
        messages: {
          pattern: 'companyOrganisationNumber must be formated xxxxxx-xxxx',
        },
      },
      type: {
        description: '',
        allowEmpty: false,
        type: 'string',
        // enum: [
        // "Aktiebolag"
        // "Handelsbolag",
        // "Kommanditbolag",
        // "Enskild Näringsverksamhet",
        // "Ideel förening",
        // "Ekonomisk förening",
        // "EEIG",
        // "Europabolag",
        // "Filial",
        // "Statlig Myndighet",
        // "Kommun",
        // "Landsting",
        // "Bemanningsföretag",
        // ]
      },
      industry: {
        description: '',
        allowEmpty: false,
        type: 'string',
      },
      size: {
        description: '',
        allowEmpty: false,
        type: 'string',
      },
      address: {
        description: '',
        allowEmpty: false,
        type: 'string',
      },
      zipCode: {
        description: '',
        allowEmpty: false,
        type: 'string',
      },
      cityOrDistrict: {
        description: '',
        allowEmpty: false,
        type: 'string',
      },
      website: {
        description: '',
        allowEmpty: false,
        type: 'string',
      },
      missionStatement: {
        description: '',
        allowEmpty: false,
        type: 'string',
      },
      contacts: {
        description: '',
        allowEmpty: false,
        type: 'array',
        properties: {
          contact: {
            type: 'object',
            allowEmpty: false,
            properties: {
              name: {
                description: '',
                allowEmpty: false,
                type: 'string',
              },
              title: {
                description: '',
                allowEmpty: false,
                type: 'string',
              },
              phone: {
                description: '',
                allowEmpty: false,
                type: 'string',
              },
              email: {
                description: '',
                allowEmpty: false,
                type: 'string',
                format: 'email',
              },
            },
          },
        },
      },
      country: {
        description: '',
        allowEmpty: false,
        type: 'string',
      },
    },
  };
  