import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Home from "./pages/home";
import "./assets/css/App.css";
import configAxios from "./services/index";
// import MainLayout from "./pages/layouts/MainLayout";
// import AuthLayout from "./pages/layouts/AuthLayout";
import CompanyEdit from "./pages/company-edit";
import JobCreate from "./pages/job-create";
import JobList from "./pages/job-list";
import JobCandidateList from "./pages/job-candidate-list";
import AccountProfile from "./pages/account-profile";
import CompanyAccess from "./pages/login";
import { useEffect, useState } from "react";
// import Strings from "./localization/translation";
import { ProtectedRoute } from "./components/protected.route";
import Activation from "pages/activation";
import ForgotPassword from "pages/accounts/forgot-password";
import NewPassword from "pages/accounts/recover-password";
import useAppLanguage from "hooks/useLanguage";
import Strings from "localization/translation";

configAxios();

function App() {
  const [appLanguage, setAppLanguage] = useState()
  const { language } = useAppLanguage();

  const refresh = () => {
    setAppLanguage(language);
    console.log(appLanguage);
  }
  useEffect(() => {
    Strings.setLanguage(language);
    refresh();
  }, [language]);
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/signup" component={CompanyAccess} />
          <Route exact path="/login" component={CompanyAccess} />
          <Route exact path="/forgot-password" component={ ForgotPassword } />
          <Route exact path="/recover-password/:token" component={ NewPassword } />
          <ProtectedRoute exact path="/" component={JobList} />
          <ProtectedRoute exact path="/company" component={CompanyEdit} />
          <ProtectedRoute exact path="/jobs" component={JobList} />
          <ProtectedRoute exact path="/jobs/create" component={ JobCreate } />
          <ProtectedRoute exact path="/jobs/:id/edit" component={ JobCreate } />

          <ProtectedRoute
            exact
            path="/jobs/:id/:title/applicants"
            component={JobCandidateList}
          />
          <ProtectedRoute
            exact
            path="/accounts/:id"
            component={AccountProfile}
          />
          <Route
            exact
            path="/account/activation/:token/:code"
            component={Activation}
          />
          {/* <Route path="/jobs/:jobAdId/applicants/:applicantId" /> */}
          <Route path="*" render={() => "404 NOT FOUND"} />
        </Switch>
      </Router>
    </>
  );
}
export default App;
