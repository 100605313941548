
function createErrorMessageElement (inputElement: HTMLElement, message: string, id: string) {
    const existingErrorElement = document.getElementById(id);
    if(!existingErrorElement) {
        const newElement = document.createElement("div")
        newElement.classList.add("error-message");
        newElement.innerHTML = message;
        newElement.id = id;
        newElement.style.fontWeight = "bold"

        inputElement.insertAdjacentElement("afterend", newElement);
        if(["INPUT", "SELECT", "TEXTAREA"].includes(inputElement.tagName)) inputElement.style.border = "1px solid red"
       
        
        return;
    }
    //else
    existingErrorElement.innerHTML = message
 }

function removeErrorMessageElement(parentInput: HTMLElement, errorMessageElementUniqueId: string){
     //remove error message element if element has no input error
     parentInput.style.removeProperty("border");
     document.getElementById(errorMessageElementUniqueId)?.remove();
}


export {
    createErrorMessageElement,
    removeErrorMessageElement
}