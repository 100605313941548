import { useState } from 'react';

export default function useAppLanguage() {
  const getCurrentLanguage = () => {
    const accountLanguageString = localStorage.getItem('account_language');
    if(JSON.parse(accountLanguageString) == null) {
      const domain = window.location.hostname;
      if(domain.includes('.de')) return 'de';
      return "sv";
    }
    return JSON.parse(accountLanguageString);
  };

  const setAccountLanguage = (language) => {
    localStorage.setItem('account_language', JSON.stringify(language));
  }

  const [language] = useState(getCurrentLanguage());

  return {
    setAppLanguage: setAccountLanguage,
    language
  }
}