import React from 'react';
import AuthHeader from './PublicHeader';

const AuthLayout =({children}) =>{
    return(
      <>
      <AuthHeader></AuthHeader>
      <section className="dashboard-area">
        <div className="dashboard-content-wrap" style={{paddingLeft: 0}}>
          <div className="container-fluid">
            { children }
          </div>
        </div>
      </section>

    </>
    )
}

export default AuthLayout;