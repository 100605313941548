
const mapLocationsByCountry = (items, id = "COUNTRY#SE", link = "sk") => {
    return items
    .filter((item) => item[link] === id)
    .sort((a, b) => a.en.localeCompare(b.en))
    .map((item) => ({ ...item, children: mapLocationsByCountry(items, item.pk) }));
};

export const getAllLocations = () => {
  const data = require("../../util/locations/countries.json");
  const countries = [
    { en: "Germany", sv: "Tyskland", de: "Tyskland", value: "COUNTRY#DE", pk:"COUNTRY#DE", children: mapLocationsByCountry(data, "COUNTRY#DE") },
    { en: "Sweden", sv: "Sverige", de: "Sverige", value: "COUNTRY#SE", pk:"COUNTRY#SE", children: mapLocationsByCountry(data, "COUNTRY#SE") },
  ];
  return countries;
};

