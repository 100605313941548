import { notification } from "antd";

export enum NotificationType {
  ERROR = "error", 
  SUCCESS = "success",
  WARNING = "warning",
  INFO = "info"
}
const showNotificationWithIcon = (title: string, description: string, type: NotificationType = NotificationType.ERROR) => {
    notification[type]({
      message: title,
      description: description,
      duration: 7,   
    });
  };


  export {
      showNotificationWithIcon
  }