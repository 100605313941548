import Strings from "../../../localization/translation";

///will investigate further before commiting to this solution
const  getTranslations=  () => {
  const language =  localStorage.getItem("userLanguage");
  const translations = require(`../../../localization/${language}.json`)
  return translations;
}

export default {
  
    properties: {
      email: {
        description: '',
        required: true,
        allowEmpty: false,
        format: 'email',
        messages: {
            required: Strings.errors?.emailRequired,
            format: 'Email format',
          },
      },
      password: {
        description: '',
        required: true,
        type: 'string',
        minLength: 8,
        pattern: /\d/,
        pattern: /[a-z]/,
        pattern: /[A-Z]/,
        messages: {
          pattern:
            'Password must contain at least one number, one uppercase letter and one lowercase letter (English alphabet)',
        },
      },
    },
  };
  