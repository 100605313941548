import { AxiosResponse } from "axios";
import Strings from "localization/translation";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { companySizes, companyTypes, industries } from "services/lookup-data";
import { NotificationType, showNotificationWithIcon } from "services/notifications";
import { handleRequest } from "../services/api-handler";
import companyService from "../services/companies";
import { ICompany } from "../services/companies/interface";
import validateModel from "../util/validation";
import { IValidationModelTypes } from "../util/validation/interface";
import { showValidationErrors } from "../util/validation/ui-messages";
import MainLayout from "./layouts/PrivateLayout";
import FileUploader from "components/file-uploader";
import useToken from "hooks/useToken";

function CompanyEdit () {
  const { push } = useHistory();
  const [changes, setChanges] = useState<any>();
  let defaultImage = '/assets/images/dots.png'
  const [previewImage, setPreviewImage] = useState(defaultImage);
  const [imageToUpload, setImageToUpload] = useState(null);

  const { account, updateLogo } = useToken();

  useEffect(() => {
    if(account?.company?.id){
      companyService.getCompanyById(account?.company?.id)
      .then((response: AxiosResponse) => {
          setChanges(response.data);
  
          if(response.data.logoUrl){
            setPreviewImage(`${response.data.logoUrl}?v=${new Date().getTime()}`)
          }
      })
    }
}, [])


//move to actions
  const uploadLogo = async (imageToUpload, companyId) => {
    
      const filePath = new FormData();

      filePath.append('file', imageToUpload.file);
      filePath.append('fileName', 'companyLogo')
      filePath.append('companyId', companyId);

      const [uploadError, uploadSuccess] = await handleRequest(companyService.uploadCompanyFile(filePath));
      if(uploadError) console.log(uploadError)
      
      if(uploadSuccess)
         {
            showNotificationWithIcon(Strings.success.logoUpload, "", NotificationType.SUCCESS);
            setImageToUpload(null);
            updateLogo();

            window.location.reload();
          }
  }

  const updateCompany = async() => {

    changes.id = account?.company?.id;
    delete changes.createdAt;
    delete changes.updatedAt;
    delete changes.files;
    delete changes.sk;
    delete changes.pk; 
    delete changes.logoUrl; 
    delete changes.activated;

    const errors = validateModel(changes, IValidationModelTypes.EDIT_COMPANY);
    if (showValidationErrors([formCompanyRef], errors)) return;

    const [companyUpdateError, companyUpdateSuccess] = await handleRequest(companyService.updateCompany(changes));

    if(companyUpdateSuccess){
      showNotificationWithIcon(Strings.success.companyInfo, "", NotificationType.SUCCESS);
    } else if(companyUpdateError){
      showNotificationWithIcon(Strings.errors.errorTitle, "", NotificationType.ERROR);
      //update error
    }
  }

  const formCompanyRef = React.createRef<HTMLFormElement>();
    return (
      <>
       <MainLayout>
           <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="breadcrumb-content d-flex flex-wrap justify-content-between align-items-center">
                    <div className="section-heading">
                      <h2 className="sec__title">{ Strings.company.editCompanyTitle }</h2>
                    </div>
                    <ul className="list-items d-flex align-items-center">
                      <li className="active__list-item">
                        <a href="/">{Strings.breadcrumbs.home }</a>
                      </li>
                      <li className="active__list-item">{Strings.breadcrumbs.account }</li>
                      <li>{Strings.breadcrumbs.edit }</li>
                    </ul>
                  </div>
                </div>
              </div>
              {
                <div className="row mt-5">
                  <div className="col-lg-12">
                    <div className="user-profile-action-wrap mb-5">
                      <div>
                        <div className="row">
                        <div className ="col-md-12" style={{ display: "flex", justifyContent: "center"}}>
                        {<img
                            src={ previewImage  }
                            alt="user-image"
                            className="img-fluid radius-round border"
                            style={{width: "200px"}}
                          />}
                          <div>
                         
                          </div>
                        </div>
                        <div className="col-md-12" style={{textAlign: "center"}}>
                          <FileUploader uploadButtonLabel={Strings.company.changeLogoButton} acceptTypes={"image/*"}
                            onFileUploaderDone={(file) => {
                              setImageToUpload({
                                path: file.name,
                                file: file
                              })
                            }}>
                          </FileUploader>
                          {imageToUpload && <button
                              className="theme-btn btn-primary mt-3 border-0"
                              type="button"
                              onClick={() => uploadLogo(imageToUpload, account?.company?.id)}
                              style={{marginLeft: 10}}
                            >
                              <i className="fa fa-save"></i> { Strings.company.saveLogo }
                          </button>}
                        </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                      <div className="user-form-action">
                        <div className="billing-form-item">
                          <div className="billing-title-wrap">
                            <h3 className="widget-title pb-0">{ Strings.company.companyProfile }</h3>
                            <div className="title-shape margin-top-10px"></div>
                          </div>
                          <div className="billing-content">
                            <div className="contact-form-action">
                              <form method="post" name="formCompanyRef" ref={formCompanyRef}>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="input-box">
                                      <label className="label-text">
                                      { Strings.company.name }
                                      </label>
                                      <div className="form-group">
                                        <span className="la la-building-o form-icon"></span>
                                        <input
                                          className="form-control"
                                          type="text"
                                          placeholder={`${ Strings.company.name }`}
                                          name="name"
                                          value={changes?.name}
                                          onChange={(e) =>
                                            setChanges(
                                              (prevState: ICompany | null) => ({
                                                ...prevState,
                                                name: e.target.value,
                                              })
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="input-box">
                                      <label className="label-text">
                                      { Strings.company.organisationNumber }
                                      </label>
                                      <div className="form-group">
                                        <span className="la la-user form-icon"></span>
                                        <input
                                          className="form-control"
                                          type="text"
                                          placeholder={`${ Strings.company.organisationNumber }`}
                                          value={changes?.organisationNumber }
                                          name="organisationNumber"
                                          onChange={(e) =>
                                            setChanges(
                                              (prevState: ICompany) => ({
                                                ...prevState,
                                                organisationNumber: e.target.value,
                                              })
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="input-box">
                                      <label className="label-text">
                                      { Strings.company.companyType }
                                      </label>
                                      <div className="form-group">
                                        <select className="form-control"
                                        value={changes?.type }
                                        name="type"
                                        onChange={(e) =>
                                          setChanges(
                                            (prevState: ICompany) => ({
                                              ...prevState,
                                              type: e.target.value,
                                            })
                                          )
                                        }
                                        >
                                          {
                                            companyTypes.sort((a,b) => a[Strings.getLanguage()].localeCompare(b[Strings.getLanguage()])).map(item => {
                                              return <option value={item.id} key={item.id}>{item[Strings.getLanguage()]}</option>
                                            })
                                          }
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="input-box">
                                      <label className="label-text">
                                        {Strings.company.industry}
                                      </label>
                                      <div className="form-group">
                                        <select className="form-control"
                                        value={changes?.industry }
                                        name="industry"
                                        onChange={(e) =>
                                          setChanges(
                                            (prevState: ICompany) => ({
                                              ...prevState,
                                              industry: e.target.value,
                                            })
                                          )
                                        }
                                        >
                                          {
                                            industries.sort((a,b) => a[Strings.getLanguage()].localeCompare(b[Strings.getLanguage()])).map(item => {
                                              return <option value={item.id} key={item.id}>{item[Strings.getLanguage()]}</option>
                                            })
                                          }
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                  <div className="input-box">
                                    <label className="label-text">
                                    {Strings.company.size}
                                    </label>
                                    <div className="form-group">
                                      <div className="form-group">
                                        <select className="form-control"
                                        value={ changes?.size }
                                        name="size"
                                        onChange={(e) =>
                                          setChanges(
                                            (prevState: ICompany) => ({
                                              ...prevState,
                                              size: e.target.value,
                                            })
                                          )
                                        }>
                                           {
                                            companySizes.map(item => {
                                              return <option value={item.id} key={item.id}>{item[Strings.getLanguage()]}</option>
                                            })
                                          }
                                        
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                  <div className="col-lg-12">
                                    <div className="input-box">
                                      <label className="label-text">
                                       {Strings.company.mission}
                                      </label>
                                      <div className="form-group">
                                        <textarea
                                          className="message-control form-control pt-3 pr-4 pb-3 pl-4"
                                          name="missionStatement"
                                          value={changes?.missionStatement }
                                          onChange={(e) =>
                                            setChanges(
                                              (prevState: ICompany) => ({
                                                ...prevState,
                                                missionStatement: e.target.value,
                                              })
                                            )
                                          }
                                        >
                                          {changes?.missionStatement}
                                        </textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="user-form-action">
                      <div className="billing-form-item">
                        <div className="billing-title-wrap">
                          <h3 className="widget-title pb-0">
                            { Strings.company.contactTitle }
                          </h3>
                          <div className="title-shape margin-top-10px"></div>
                        </div>
                        <div className="billing-content">
                          <div className="contact-form-action">
                            <form method="post">
                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="input-box">
                                    <label className="label-text">
                                    { Strings.forms.phone }

                                    </label>
                                    <div className="form-group">
                                      <span className="la la-phone form-icon"></span>
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="contacts.0.phone"
                                        placeholder="+46 222-222-22"
                                        value={(changes?.contacts?.length > 0 ? changes?.contacts[0].phone : null )}
                                        onChange={(e) =>
                                          setChanges(
                                            (prevState: ICompany) => {
                                              if(!prevState.contacts)  prevState.contacts = [{}]
                                              prevState.contacts[0].phone = e.target.value;
                                              return {
                                                ...prevState,
                                              }
                                            })
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="input-box">
                                    <label className="label-text">
                                    { Strings.forms.email }
                                    </label>
                                    <div className="form-group">
                                      <span className="la la-envelope form-icon"></span>
                                      <input
                                        className="form-control"
                                        type="email"
                                        name="contacts.0.email"
                                        placeholder=""
                                        value={(changes?.contacts?.length > 0 ? changes?.contacts[0].email : null ) 
                                        }
                                        onChange={(e) =>
                                          setChanges(
                                            (prevState: ICompany) => {
                                              if(!prevState.contacts)  prevState.contacts = [{}]
                                              prevState.contacts[0].email = e.target.value;
                                              return {
                                                ...prevState,
                                              }
                                            })
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="input-box">
                                    <label className="label-text">{ Strings.forms.website }</label>
                                    <div className="form-group">
                                      <span className="la la-external-link form-icon"></span>
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="website"
                                        placeholder=""
                                        value = {changes?.website }
                                        onChange={(e) =>
                                          setChanges(
                                            (prevState: ICompany) => ({
                                              ...prevState,
                                              website: `${e.target.value}`,
                                            })
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="input-box">
                                    <label className="label-text">{ Strings.forms.country }</label>
                                    <div className="form-group">
                                      <select className="form-control"
                                        name="country"
                                        onChange={(e) =>
                                          setChanges(
                                            (prevState: ICompany) => ({
                                              ...prevState,
                                              country: e.target.value,
                                            })
                                          )
                                        }
                                      value={changes?.country }
                                      >
                                        <option value="">
                                          {Strings.chooseCountry}
                                        </option>
                                        {
                                          Object.entries(Strings.countries).map(item => {
                                            return <option value={item[0]} key={item[0]}>{item[1]}</option>
                                          })
                                        }
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="input-box">
                                    <label className="label-text">{Strings.forms.city}</label>
                                    <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="cityOrDistrict"
                                        placeholder=""
                                        value={changes?.cityOrDistrict }
                                        onChange={(e) =>
                                          setChanges(
                                            (prevState: ICompany) => ({
                                              ...prevState,
                                              cityOrDistrict: e.target.value,
                                            })
                                          )
                                        }
                                      />
                                      {/* <select className="form-control"
                                      name="cityOrDistrict"
                                      onChange={(e) =>
                                        setChanges(
                                          (prevState: ICompany) => ({
                                            ...prevState,
                                            cityOrDistrict: e.target.value,
                                          })
                                        )
                                      }
                                    value={changes?.cityOrDistrict }
                                      >
                                        <option>Select a City</option>
                                        <option value="jonkoping">Jonkoping</option>
                                        <option value="Huskvarna">Huskvarna</option>
                                      </select> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="input-box">
                                    <label className="label-text">
                                    {Strings.forms.address}
                                    </label>
                                    <div className="form-group">
                                      <span className="la la-map-marker form-icon"></span>
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="address"
                                        placeholder=""
                                        value = {changes?.address }
                                        onChange={(e) =>
                                          setChanges(
                                            (prevState: ICompany) => ({
                                              ...prevState,
                                              address: e.target.value,
                                            })
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                            </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                <div className="col-lg-12">
                  <div className="btn-box">
                    <button className="theme-btn border-0" type="button"
                     onClick={() => updateCompany()}
                    >
                      { Strings.actions.updateChanges }
                    </button>
                  </div>
                </div>
               </div>
              }
              <div className="row">
                <div className="col-lg-12">
                  <div className="copy-right margin-top-30px padding-top-20px padding-bottom-20px">
                    <p className="copy__desc">
                      Copyright &copy;2021
                      <a
                        href="https://www.allabolag.se/5592038656/blockchainx-ab"
                        target="_blank"
                      >
                        BlockchainX AB made with{" "}
                        <span className="la la-heart-o"></span> in Jönköping
                      </a>
                    </p>
                    <ul className="list-items">
                      <li>
                        <a
                          href="https://www.iubenda.com/terms-and-conditions/72847642"
                          target="_blank"
                        >
                           { Strings.agreeToTermsAndConditions },
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.iubenda.com/privacy-policy/72847642/full-legal"
                          target="_blank"
                        >
                           { Strings.agreeToPrivacyPolicy }
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
       </MainLayout>
      </>
    );
}

export default CompanyEdit;
