import { useState } from "react";
import Strings from "../../localization/translation";

function SkillsTableRow({ skill, onRemove, onSkillLevelSelected }: any) {
  let [currentSkillLevel, setCurrentSkillLevel] = useState(skill?.level || 1);

  return (
    <tr>
      <td
        style={{
          width: "30%",
          alignItems: "center",
        }}
      >
        { skill[Strings.getLanguage()] }
      </td>
      <td style={{ width: "20%" }}>
        <select
        data-cy="selectSkillLevel"
        className="form-control"
          value={ currentSkillLevel }
          onChange={(e) => {

            setCurrentSkillLevel(e.target.value);
            onSkillLevelSelected({
              ...skill,
              level: e.target.value
            })
          }}>
          {
          Object.values(Strings.skillLevel[skill.type]).map((item: any, index) => {
              return <option key={`${index}`} value={index+1}> {index+1} - {item.label}</option>
            })
          }
        </select>
      </td>
      <td style={{ width: "40%", textAlign: "justify" }}>
        {
         Strings.skillLevel[skill.type][`level${currentSkillLevel}`]?.helpText
        }
      </td>
      <td style={{ verticalAlign: "middle" }}>
        <a className="color-text" href="#!" onClick={() => onRemove(skill.id) }>
          <i className="la la-minus-circle la-2x"></i>
        </a>
      </td>
    </tr>
  );
}

export default function RequiredSkillsTable({ skills, onListUpdated }: any) {
  const onItemRemoved = (id: string) =>{
    const updatedSkills = skills.filter((x:any) => x.id != id)
    onListUpdated(updatedSkills);
  }

  const onSkillLevelSelected = (skill: any) =>{
    skills.find((x: any) => x.id == skill.id).level = skill.level;
    onListUpdated(skills);
  }

  return (
    <>
    {
    skills && skills.length > 0 && (
      <table className="table">
        <thead>
          <tr>
            <th>{ Strings.skillNameTitle }</th>
            <th>{ Strings.skillLevelRequiredTitle }</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {skills && skills?.map((item: any) => {
            return <SkillsTableRow key={item.id} skill={item} onRemove={ onItemRemoved } onSkillLevelSelected={onSkillLevelSelected}></SkillsTableRow>;
          })}
        </tbody>
      </table>
    )}
    </>
  );
}
