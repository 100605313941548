const colorScheme = [
  { color: "#0d47a1", level: 5 },
  { color: "#1976d2", level: 4 },
  { color: "#1e88e5", level: 3 },
  { color: "#2196f3", level: 2 },
  { color: "#42a5f5", level: 1 },
];

const skillMatchScheme = [
  { color: "green", match: "matching" },
  { color: "red", match: "missing" },
  { color: "gray", match: "extra" }
];

type ISkillCard = {
  title: string;
  badge: any;
  type: string;
  mainColor?: string;
  match?: string;
};

export default function SkillCard({ title, badge,  match, mainColor = "#3F8EFC" }: ISkillCard) {
  const badgeColor = skillMatchScheme?.find((x) => x.match === match)?.color;
  return (
    <>
      <div
        style={{
          backgroundColor: badgeColor,
          borderRadius: 4,
          margin: 10,
          padding: "0.2em",
          position: "relative",
        }}
      >
        <div
          style={{
            flexDirection: "row",
            padding: 2,
            paddingLeft: 5,
            paddingRight: 5,
          }}
        >
          {
            <div
              style={{
                backgroundColor: mainColor,
                position: "absolute",
                top: -9,
                left: -9,
                width: 18,
                borderRadius: 9,
                height: 18,
                textAlign: "center",
                color: "white",
                fontSize: 10,
              }}
              title={`Skill level ${badge || 3}` }
            >
              <b>{ badge || 3 }</b>
            </div>
          }
          <div
            style={{
              color: "white",
              textAlign: "center",
              width: "auto",
              fontSize: 12,
            }}
          >
            {title}
          </div>
        </div>
      </div>
    </>
  );
}
