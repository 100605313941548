import axios from "axios";

const authService = { 
    login: (data) => {
     return new Promise<AxiosResponse>((resolve, reject) =>
      axios
        .post("account/login", data, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((resp) => {
           resolve(resp)
        })
        .catch((e) => reject(e)));
    }
    ,
    logout: (callback) => {
        localStorage.removeItem("token");
        localStorage.removeItem("account");
        callback();
    }
    ,
    isLoggedIn: () => {
        return localStorage.getItem("token") != null;
    }
}

export default authService;