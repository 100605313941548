import { useState } from 'react';

export default function useAuth() {
  const getCurrentAccount = () => {
    const accountString = localStorage.getItem('account');
    return JSON.parse(accountString);
  };

  const [account, setAccount] = useState(getCurrentAccount());

  return {
    account
  }
}