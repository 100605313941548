import { Component } from "react";
import MainLayout from './layouts/PrivateLayout';
import AccountView from "../components/job-applicants/account-view"

class AccountProfile extends Component {
  render() {
    return (
      <>
      <MainLayout>
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb-content d-flex flex-wrap justify-content-between align-items-center">
              <div className="section-heading">
                <h2 className="sec__title"> </h2>
              </div>
              <ul className="list-items d-flex align-items-center">
                <li className="active__list-item">
                  <a href="index.html">Jobs</a>
                </li>
                <li className="active__list-item">
                  <a href="index.html">Job-Id</a>
                </li>
                <li>Job Applicant</li>
              </ul>
            </div>
          </div>
        </div>
        </MainLayout>
      </>
    );
  }
}

export default AccountProfile;
