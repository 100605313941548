const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

// Phone and email cannot both be empty at the same time, this check is done in the account-manager file

export const createAccountSchema = {
  properties: {
    organisationNumber: {
      description: 'Company org number is required',
      allowEmpty: true,
      type: ['string', 'null'],
      pattern: /^\d{6}-\d{4}$/,
      messages: {
        pattern: 'Company Organisation number must be formated XXXXXX-XXXX',
      },
    },
    email: {
      description: '',
      type: 'string',
      allowEmpty: false,
      required: true,
      format: 'email',
    },
    companyId:{
      type: ["null", "string"],
      allowEmpty: true
    },
    type: {
      description:
        'Account type is company',
      required: true,
      allowEmpty: false,
      type: 'integer',
      minimum: 2,
      maximum: 2
    },
    password: {
      description: '',
      required: true,
      allowEmpty: false,
      type: 'string',
      minLength: 8,
      pattern: passwordRegex,
      messages: {
        pattern:
          'Password must contain at least one uppercase letter, one lowercase letter and one number',
      },
    },
    confirmPassword: {
      description: '',
      required: false,
      allowEmpty: false,
      type: 'string',
    },
    termsAndConditionsAccepted: {
      required: true,
      enum:[true]
    },
    privacyTermsAccepted: {
      required: true,
      enum:[true]
    },
    country: {
      type: 'string'
    }
  },
};

export const extendValidationOfCreateAccount = (errors, accountToCreate) => {
  if(accountToCreate.password !== accountToCreate.confirmPassword){
    if(!errors["confirmPassword"])
      errors["confirmPassword"] = {
        field: "confirmPassword",
        message: "Passwords do not match."
      }
  }
  if(accountToCreate.country === 'Sweden' && !accountToCreate.organisationNumber){
    if(!errors["organisationNumber"])
      errors["organisationNumber"] = {
        field: "organisationNumber",
        message: "You need to provide an organisation number"
      }
  }
  return errors;
}

