/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import Strings from 'localization/translation';

const API_URL = process.env.REACT_APP_API_URL

const configAxios = () => {
    axios.defaults.baseURL = API_URL;
    axios.defaults.timeout = 60000;
    axios.interceptors.request.use(async (request) => {
        let token = await localStorage.getItem('token');
       
        if (token){
            token = JSON.parse(token);
            if(!request.headers.authorization)
                 request.headers.authorization = `Bearer ${token}`;
        }
        if(Strings.getLanguage())
             request.headers.language =  Strings.getLanguage();
        return request;
    }, (error) => {
        Promise.reject(error);
    });

    axios.interceptors.response.use(
        async (response) => {
            return response;
        }, async (error) => {
            return Promise.reject(error);
        },
    );
};



export default configAxios;
