import React, { Component } from 'react';
import LanguageBar from 'pages/app-config/language-bar';
import Strings from 'localization/translation';

class AuthHeader extends Component {
    render() {
        return (
            <>
                <header className="header-area header-desktop">
                    <div className="header-menu-wrapper">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="menu-full-width">
                                        <div className="logo">
                                            <a href="/"><img style={{ width: 150 }} src="/assets/images/logo2.png" alt="logo" /></a>
                                        </div>
                                        <div className="main-menu-content">
                                            <nav>
                                                <ul>
                                                    <li>
                                                        <a href="/"> { Strings.jobs } </a>
                                                    </li>
                                                
                                                    <li>
                                                        <a href="https://employchain.se" target="_blank">{ Strings.AboutUs }</a>
                                                    </li>
                                               
                                                </ul>
                                            </nav>
                                        </div>
                                        <div className="logo-right-content">
                                                 <div className="notification-wrap d-flex align-items-center" style={{ display: 'none' }}>
                                                    <div className="notification-item mr-3">
                                                       <LanguageBar ></LanguageBar>
                                                    </div>
                                         </div>
                                            {/* <div className="header-action-button d-flex align-items-center">
                                             
                                                <div className="user-action-wrap">
                                                    <div className="notification-item">
                                                        <div className="dropdown">
                                                            <button className="notification-btn dot-status online-status dropdown-toggle" type="button" id="userDropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <img src="/assets/images/preview.png" alt="Bluetech,Inc" />
                                                            </button>
                                                         </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="menu-toggler d-flex align-items-center">
                                                <div className="side-menu-open">
                                                    <span className="menu__bar"></span>
                                                    <span className="menu__bar"></span>
                                                    <span className="menu__bar"></span>
                                                </div>
                                                <div className="user-menu-open">
                                                    <i className="la la-user"></i>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </>
        );
    }
}
export default AuthHeader;