import React, { useState } from 'react';
import ImgCrop from 'antd-img-crop';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const getSrcFromFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.originFileObj);
    reader.onload = () => resolve(reader.result);
  });
};

function FileUploader(props) {
  const [fileList, setFileList] = useState([]);
  const uploadRef = React.useRef(null);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    props.onFileUploaderDone(newFileList[0].originFileObj);
  };

  return (
    <ImgCrop grid rotate >
      <Upload
      ref={uploadRef}
        action={null}
        accept={props.acceptTypes}
        maxCount={1}
        listType="picture"
        fileList={fileList}
        beforeUpload={() => false}
        onChange={onChange}>
       
        <Button icon={<UploadOutlined />}> { props.uploadButtonLabel }</Button>
      </Upload>
    </ImgCrop>
  );
};


export default FileUploader;