import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import jobService from '../services/jobs';
import MainLayout from './layouts/PrivateLayout';
import AccountView from '../components/job-applicants/account-view'
import { Empty } from 'antd';
import Strings from 'localization/translation';
import ChatBox, { ChatScreen } from './chat';
import useToken from 'hooks/useToken';
import Chat2 from './chat/index';

function JobCandidateList(props) {
    const [applicantList, setApplicantList] = useState([]);
    const [applicationItem, setApplicationItem] = useState(null);
    const [jobAd, setJobAd] = useState(null);
    const { id: jobAdId, title } = useParams();
    const {account} = useToken();

    useEffect(async() => {
        const jobData = await jobService.getJobById(jobAdId);
        if(jobData){
            setJobAd(jobData.data);
        }

        const result = await  jobService.getApplicantsOfJobAd(jobAdId);
        if(result){
            const applications = result.data.map((item) => {
                return {
                    ...item,
                    application: {
                        ...item.application,
                        matchingValue: Math.round((item.application.matchingValue)*100)
                    },
                    applicant: {
                        ...item.applicant,
                        id: extractId(item.applicant.pk)
                    }
                }
            });
            const sortedApplications = applications.sort((a, b) => parseFloat(b.application.matchingValue) - parseFloat(a.application.matchingValue))
            setApplicantList(sortedApplications);
        }
    }, [])

    const extractId = (pk, prefix='ACC#SEEK#') => {
        return pk.replace(prefix, '');
    }
    const setCurrentApplicationToView = (index) => {
        const applicationItem = applicantList[index];
        setApplicationItem(applicationItem);
     }
    return (
        <>
            <MainLayout>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-content d-flex flex-wrap justify-content-between align-items-center">
                            <div className="section-heading">
                                <h2 className="sec__title"> {title}  #({applicantList.length} {Strings.jobAd.list.applicants }) </h2>
                            </div>
                            <ul className="list-items d-flex align-items-center">
                                <li className="active__list-item"><a href="/jobs">{Strings.jobAd.list.applicants }</a></li>
                                <li className="active__list-item"><a href={`/jobs/${ jobAdId }/edit`}>{ jobAd?.title }</a></li>
                                <li> {Strings.jobAd.list.applicants } </li>
                            </ul>
                        </div>
                    </div>
                </div>
                { applicantList.length > 0  && (
                <>
                    <div className="row mt-5" style={{ paddingLeft: 50, paddingRight: 50}}>
                        <div className="col-lg-12">
                            <div className="billing-form-item">
                                <div className="billing-title-wrap ModalViewHeader">
                                                                    
                                        {applicationItem &&  <h3 className="widget-title pb-0"> <a href="#!" style={{ textDecoration: 'none', color: '#FFC530'}} onClick={() => setApplicationItem(null)}><span className="mr-4"><i className="la la-long-arrow-alt-left"></i> { Strings.actions.backButton } </span> </a></h3>}
                                        {/* {applicationItem == null &&  <> <h3 className="widget-title pb-0">{applicantList.length} Candidate</h3>  <div className="title-shape margin-top-10px"></div></> } */}
                                        {applicationItem == null &&  <>
                                            <div>
                                                <input className="form-control" placeholder={`${Strings.actions.searchText }`}></input>    
                                            </div>
                                        </> 
                                        }
                                </div>

                                {
                                applicationItem && <div className="billing-content pb-0 ModalView"> 
                                        <AccountView account={ applicationItem.applicant } application={ applicationItem.application } jobSkills = { jobAd.skills }></AccountView>
                                    </div>
                                }
                                { applicationItem == null && applicantList && <div className="billing-content pb-0">
                                    <div className="row">
                                    {
                                        applicantList.map((item, index) => {
                                            return (
                                                <div key={item.id} className="col-md-6">
                                                    <div className="manage-candidate-wrap d-flex align-items-center justify-content-between pb-4 pt-4">
                                                        <div className="bread-details d-flex">
                                                            <div className="bread-img flex-shrink-0">
                                                                <a  className="d-block" onClick={() => setCurrentApplicationToView(index)}>
                                                                    <img src={item.applicant.profile_picture_url || "/assets/images/avatar-img.png" } alt="" />
                                                                </a>
                                                            </div>
                                                            <div className="manage-candidate-content">
                                                                <h2 className="widget-title pb-2"><a href="#!" onClick={() => setCurrentApplicationToView(index)} className="color-text-2"> {item.applicant?.firstName} {item.applicant?.lastName} </a></h2>
                                                                <p className="font-size-15">
                                                                    <span className="mr-2"><i className="la la-envelope-o mr-1"></i><a href={`mailto: ${item.applicant?.email}`} className="color-text-3">{item.applicant?.email}</a></span>
                                                                    {item.applicant?.contact?.phone && <span className="mr-2"><i className="la la-phone mr-1"></i>{item.applicant?.contact?.phone}</span>}
                                                                </p>
                                                                <p className="mt-1 font-size-15">
                                                                    <span className="mr-2"><i className="la la-map mr-1"></i>{ Strings.matchingScore } </span>
                                                                    <span className="rating-rating">
                                                                        <span className="rating-count">{item.application?.matchingValue}%</span>
                                                                        {/* <span className="la la-star"></span>
                                                            <span className="la la-star"></span>
                                                            <span className="la la-star"></span>
                                                            <span className="la la-star"></span>
                                                            <span className="la la-star-half-alt"></span> */}
                                                                    </span>
                                                                </p>
                                                                <p className="mt-1 font-size-15 color-text-3">
                                                                    {item.application?.createdAt && <span className="mr-2"><i className="la la-clock mr-1"></i> { new Date(item.applicant?.createdAt).toLocaleDateString() }</span>}
                                                                    {/* <span className="mr-2"><i className="la la-clock-o mr-1"></i>Full time</span> */}
                                                                    {/* <span>$25 /mo</span> */}
                                                                </p>
                                                                <p className="mt-1 font-size-15 color-text-3">
                                                                    {item.applicant?.address?.city && <span className="mr-2"><i className="la la-map-marker mr-1"></i>{item.applicant?.address?.city} {item.applicant?.address?.street} {item.applicant?.address?.postalCode}</span>}
                                                                    {/* <span className="mr-2"><i className="la la-clock-o mr-1"></i>Full time</span> */}
                                                                    {/* <span>$25 /mo</span> */}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* <div className="bread-action">
                                                            <ul className="info-list">
                                                                <li className="d-inline-block mb-0"><a href="#" ><i className="la la-user" data-toggle="tooltip" data-placement="top" title="Full profile"></i></a></li>
                                                                <li className="d-inline-block mb-0"><a href="#" ><i className="la la-cloud-download" data-toggle="tooltip" data-placement="top" title="Download CV"></i></a></li>
                                                            </ul>
                                                        </div> */}
                                                    </div>
                                                    <div className="section-block"></div>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                </div> }
                            </div>
                        </div>
                    </div>
                </>)
                 }

                 { applicantList.length === 0 && (
                    <>
                     <Empty 
                      description={
                        <span>
                            { Strings.emptyApplicantList }
                        </span>
                      }
                     />
                    </>
                  )
                  }
                  <div style={{position: 'fixed', 
                             bottom: 0, right: 10, width: "300px", height: "350px"}}>
                        {applicationItem && <ChatScreen 
                            applicantAccount={{
                                id: applicationItem?.applicant?.id,
                                name: applicationItem?.applicant?.firstName
                            }}
                            companyAccount={{
                                id: account?.companyId,
                                name: account?.company?.name,
                                image: account?.company?.logoUrl,
                                email: account.email
                            }}
                        ></ChatScreen>}
                  </div>
            </MainLayout>
        </>
    )
}

export default JobCandidateList;