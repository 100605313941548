import Strings from 'localization/translation';
import { IValidationModelTypes } from './interface';
import { transformToErrorDataStructure } from './transformsServerErrorMessage';
const revalidator = require('revalidator');
const vSchemas = require('./validation-schemas').default;



const validateModel = (model, modelType, extendValidationFunction = null) => {
  let schema = null;
  let options = {};

  const { LOGIN, CREATE_ACCOUNT, CREATE_JOBAD, EDIT_JOBAD, EDIT_COMPANY } = IValidationModelTypes; 
  switch (modelType) {
    case LOGIN:
      schema = vSchemas.login;
      options.additionalProperties = false;
      options.cast = true;
      break;
    case CREATE_ACCOUNT:
      schema = vSchemas.createAccount;
      options.additionalProperties = false;
      options.cast = true;
      break;
    case CREATE_JOBAD:
      schema = vSchemas.createJobAdSchema;
      options.additionalProperties = false;
      options.cast = true;
      break;
    case EDIT_JOBAD:
      schema = vSchemas.editJobAdSchema;
      options.additionalProperties = false;
      options.cast = true;
      break;
    case EDIT_COMPANY:
      schema = vSchemas.editCompanySchema;
      options.additionalProperties = false;
      options.cast = true;
      break;
  }

  let errors = [];

  const translatedSchema = translateSchemaMessages(schema);
  const result = revalidator.validate(model, translatedSchema, options);

  errors = transformToErrorDataStructure(result.errors)
  
  if(extendValidationFunction)
      extendValidationFunction(errors);

  return errors;
};

const translateSchemaMessages = (schema) => {
  let shemaMap = { properties: {}}
   
  Object.keys(schema.properties).map(key => {
    const propertyValue = schema.properties[key]
    shemaMap.properties[key] =  {
      ...propertyValue,
      message: propertyValue?.message?.en ? propertyValue.message[Strings.getLanguage()] : "*"
    }
  });

  return shemaMap;
}

export default validateModel;