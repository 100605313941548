import { useState } from 'react';

const S3URL = `${process.env.REACT_APP_S3URL}/companies`;
export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
  };

  const getCurrentAccount = () => {
    const accountString = localStorage.getItem('account');
    return JSON.parse(accountString);
  };

  const [token, setToken] = useState(getToken());
  const [account, setAccount] = useState(getCurrentAccount());

  const saveToken = userToken => {
    localStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken.token);
  };

  const isLoggedIn = () => {
    const tokenString = localStorage.getItem('token');
    return tokenString != null;
  }

  const setLoggedAccount = (account) => {
    localStorage.setItem('account', JSON.stringify(account));
  }
  
  const updateLogo = () => {
      const account = getCurrentAccount();
      setLoggedAccount({
        ...account,
        company:{
          ...account?.company,
          logoUrl: `${S3URL}/${account?.company.id}/companyLogo?v=${new Date().getTime}`
        }
      })
  }

  return {
    setToken: saveToken,
    token,
    isLoggedIn,
    account,
    setLoggedAccount,
    updateLogo
  }
}