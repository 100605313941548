import { AxiosError, AxiosPromise, AxiosResponse } from "axios";
import { NotificationType, showNotificationWithIcon } from "../notifications";
import { IError } from "./interface";



export const handleRequest = (promise: AxiosPromise) =>
  promise
    .then((response: AxiosResponse) => [null, response.data])
    .catch((error: AxiosError) => {
      const errorResult = processErrorMidleware(error);
      if([400, 404, 409].includes(errorResult?.code))
         return [errorResult, null];
     
      showNotificationWithIcon(`${errorResult.title}`, `${errorResult.message}`, NotificationType.ERROR);
      return [errorResult, null]
    });


//Translation
const processErrorMidleware = (error: AxiosError): IError => {
  
  if (error.response) {
      let errorMessage: any;
      const { status, data } = error.response
      if(status === 403)
           errorMessage = {
             message: data,
             title: "No permission to complete this operation:",
            }
      else if(status === 401)
           errorMessage = {
             message: "Not unauthorized to start this operation.", 
             title: "Authorization Problem"
          };
      else if(status >= 500 && status <= 600) //or 500 ish
           errorMessage ={
             message:
              `An error occured while processing your request. Refresh and Try again. If the error persists contact the Administrator Link:// ${data}`,
              title: "Processing problem",
            }
      else {
        if(status== 400)
        { 
          errorMessage ={
            message: data,
            title: "Hi, something is wrong!",
          }
        }
        if(status== 404){
          errorMessage ={
            message: data,
            title: "Resource not found",
          }
        }
      }
      return {
         ...errorMessage,
          code: status
      }
    } else {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
          return {
              message: "An unknown error occured while processing your request. Check your internet connection. Refresh and Try again. If the error persists contact the Administrator Link://",
              code: 0,
              title: "Processing Problem"
          }
    }
}