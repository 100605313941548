import Strings from 'localization/translation';
import AuthLayout from 'pages/layouts/PublicLayout';
import  { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import accountService from 'services/accounts';
import { handleRequest } from 'services/api-handler';
import { NotificationType, showNotificationWithIcon } from 'services/notifications';


function NewPassword () {
     const { token } = useParams();
     const { push } = useHistory();
     const [accountToReset, setAccountToReset ] = useState({});

     const saveNewPassword = async (accountToReset, token) => {
        if(!accountToReset) return;
        const {password, confirmPassword } = accountToReset
        if(!password) return;
        if(!confirmPassword) return;
        if(password !==  confirmPassword) {
            showNotificationWithIcon(Strings.errors.passwordsMustMatch, "", NotificationType.ERROR);
            return;
        }

        const [savePasswordError, savePasswordSuccess] = await handleRequest(
            accountService.saveNewPassword(password, token)
        )

        if(savePasswordError === null) {
            showNotificationWithIcon(Strings.success.passwordReset, "", NotificationType.SUCCESS);
            push("/login")
        }
            
     }

        return (
           <>
            <AuthLayout>
                <div className="row">
                    <div className="col-lg-12">
                    <div className="breadcrumb-content d-flex flex-wrap justify-content-between align-items-center">
                        <div className="section-heading">
                        <h2 className="sec__title line-height-45">
                            { Strings.recoverPasswordTitle }
                        </h2>
                        </div>
                        <ul className="list-items d-flex align-items-center">
                        <li className="active__list-item">
                            <a href="#!">{ Strings.breadcrumbs.accounts }</a>
                        </li>
                        <li className="active__list-item">{ Strings.breadcrumbs.newPassword }</li>
                        </ul>
                    </div>
                    </div>
                </div>
                <section class="form-shared padding-top-100px padding-bottom-100px">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 mx-auto">
                                <div class="billing-form-item mb-0">
                                    <div class="billing-title-wrap">
                                        <h3 class="widget-title font-size-28">{ Strings.recoverPasswordTitle }</h3>
                                    </div>
                                    <div class="billing-content">
                                        <div class="contact-form-action">
                                            <form method="post">
                                                <div class="input-box">
                                                    <label class="label-text">{ Strings.breadcrumbs.newPassword }</label>
                                                    <div class="form-group">
                                                        <span class="la la-envelope-o form-icon"></span>
                                                        <input class="form-control" type="password" name="newPassword"
                                                            onChange={(e) => setAccountToReset({
                                                                ...accountToReset,
                                                                password: e.target.value
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="input-box">
                                                    <label class="label-text">{ Strings.forms.confirmPassword }</label>
                                                    <div class="form-group">
                                                        <span class="la la-envelope-o form-icon"></span>
                                                        <input class="form-control" type="password" name="confirmPassword" 
                                                          onChange={(e) => setAccountToReset({
                                                            ...accountToReset,
                                                            confirmPassword: e.target.value
                                                        })}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="btn-box margin-top-20px margin-bottom-20px">
                                                    <button class="theme-btn border-0" type="button" onClick={() => saveNewPassword(accountToReset, token) }>{ Strings.forms.saveButton }</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </AuthLayout>
    </>
)
}
export default NewPassword;