export default {
    properties: {
       id: {
            description: '',
            allowEmpty: false,
            required: true,
            type: 'string',
       },
      address: {
        description: '',
        allowEmpty: true,
        type: ['null', 'string'],
      },
      zipCode: {
        description: '',
        allowEmpty: true,
        type: ['null', 'string'],
      },
      location: {
        description: '',
        required: true,
        allowEmpty: false,
        type: 'string',
      },
      cityOrDistrict: {
        description: '',
        allowEmpty: false,
        type: 'string',
      },
      geoSubLayer1: {
        description: '',
        allowEmpty: false,
        type: 'string',
      },
      geoSubLayer2: {
        description: '',
        allowEmpty: false,
        type: 'string',
      },
      contactPersonName: {
        description: '',
        allowEmpty: false,
        type: 'string',
      },
      contactPersonTitle: {
        description: '',
        allowEmpty: false,
        type: 'string',
      },
      contactPersonPhone: {
        description: '',
        allowEmpty: false,
        type: 'string',
      },
      contactPersonEmail: {
        description: '',
        allowEmpty: false,
        type: 'string',
      },
      deadline: {
        format: 'date',
        description: '',
        allowEmpty: false,
      },
      title: {
        description: '',
        allowEmpty: false,
        required: false,
        type: 'string',
      },
      position: {
        description: '',
        allowEmpty: false,
        type: 'string',
      },
      jobType: {
        description: '',
        allowEmpty: false,
        type: 'string',
        enum: [
          'fullTime',
          'partTime',
          'summerJob',
          'internship',
          'traineeship',
          'project',
          'thesis',
        ],
      },
      payment: {
        allowEmpty: false,
        type: 'string',
        enum: ['paid', 'unpaid'],
      },
      responsibilityTasks: {
        description: '',
        allowEmpty: false,
        type: 'string',
      },
      benefits: {
        description: '',
        allowEmpty: false,
        type: 'string',
      },
  
      educationLevel: {
        description: '',
        allowEmpty: false,
        type: 'string',
        enum: ['none', 'highSchool', 'bachelors', 'masters', 'phd', 'folk-high-school', 'vocational-education'],
      },
      experienceLevel: {
        description: '',
        allowEmpty: false,
        type: 'string',
        enum: [
          'none',
          'intern',
          'entryLevel',
          'associate',
          'midSeniorLevel',
          'executive',
          'director',
        ],
      },
      industries: {
        description: '',
        allowEmpty: false,
        type: 'array',
        properties: {
          type: 'string',
          allowEmpty: false,
        },
      },
      skills: {
        description: '',
        allowEmpty: false,
        required: false,
        type: 'array',
        items: {
          type: 'object',
          allowEmpty: 'false',
          properties: {
            id: {
              required: true,
              allowEmpty: false,
              type: 'string',
            },
            level: {
              required: true,
              allowEmpty: false,
              type: 'integer',
              minimum: 1,
              maximum: 5,
            },
            en: {
              required: true,
              allowEmpty: false,
              type: 'string'
            },
            sv: {
              required: true,
              allowEmpty: false,
              type: 'string'
            },
            nextLayer: {
              required: true,
              allowEmpty: false,
              type: 'string'
            },
            type: {
              required: true,
              allowEmpty: false,
              type: 'string'
            },
          },
        },
      },
  
      freeText: {
        type: 'string',
      },
      fetchedFrom: {
        type: 'string',
      },
      numberOfVacancies: {
        type: 'integer',
      },
      employmentType: {
        type: 'string',
      },
      scopeOfWork: {
        type: 'object',
        properties: {
          min: {
            type: 'integer',
            minimum: 0,
            maximum: 100,
          },
          max: {
            type: 'integer',
            minimum: 0,
            maximum: 100,
          },
        },
      },
  
      salaryType: {
        type: 'string',
      },
  
      duration: {
        type: 'string',
      },
  
      workingHoursType: {
        type: 'string',
      },
      occupationField: {
        type: 'object',
        properties: {
          jobTechId: {
            type: 'string',
            required: true
          }
        },
        required: true,
        allowEmpty: false
      },
      coordinates: {
        type: 'array',
        minItems: 2,
        maxItems: 2,
        items: {
          type: 'number',
          allowEmpty: false,
        },
      },
  
      reference: {
        type: 'string',
      },
      remoteWork: {
        type: 'string',
        enum: ['flexible', 'fullyRemote', 'fullyOnSite'],
      },
      status: {
        description: '',
        allowEmpty: false,
        required: true,
        type: 'string',
        enum: ['draft', 'template', 'published']
      },
      isFromDashboard: {
        type: 'boolean',
      },
      isPremium: {
        type: 'boolean',
      },
    },
  };
  