import Strings from "../../localization/translation";
import SkillCard from "../skill-card/index";
import moment from 'moment'
import { useEffect, useState } from "react";
import {DocumentList} from "./document-list";

function AccountView ({ account, application, jobSkills }) {
  const [matchedSkills, setMatchedSkills] = useState([]);
  useEffect(() => { 
    let matchingSkills = [];
    let missingSkills = [];
    let extraSkills = [];

    if(jobSkills){
      jobSkills?.forEach((item) => {
        const foundItem = account?.skills?.find(x => x.id === item.id)
        if(foundItem) {
          matchingSkills.push({...foundItem, match: "matching"});
        }
        else missingSkills.push({...item, match: "missing"})
      })

      account?.skills?.forEach((item) => {
        if(!jobSkills.find(x => x.id === item.id)) extraSkills.push({...item, match: "extra"})
      })

      setMatchedSkills([...matchingSkills, ...missingSkills, ...extraSkills])
    }
  }, jobSkills)
    return (
      <>
       <div className="manage-candidate-wrap d-flex align-items-center justify-content-between pb-4 pt-4">
          <div className="bread-details d-flex">
            <div className="bread-img flex-shrink-0">
              <a href="#!" className="d-block">
                {/* Fix issue in backend so no duplicate profile pictures */}
                <img src={ `${account.profile_picture_url}.jpg` } alt="" />
              </a>
            </div>
            <div className="manage-candidate-content">
              <h2 className="widget-title pb-2">
                <a className="color-text-2">
                   { account.firstName }  { account.lastName }
                </a>
              </h2>
              <p className="font-size-15">
                <span className="mr-2">
                  <i className="la la-envelope-o mr-1"></i>
                  <a
                    href={`mailto:${account?.email}`}
                    className="color-text-3">
                     { account?.email }
                  </a>
                </span>
                {account.contact?.phone && <span className="mr-2">
                  <i className="la la-phone mr-1"></i> { account.contact?.phone }
                </span>}
              </p>
              <p className="mt-1 font-size-15">
                <span className="mr-2">
                  <i className="la la-map mr-1"></i>{ Strings.applicantStatus.overallMatch }
                </span>
                <span className="rating-rating">
                  <span className="rating-count">{ application?.matchingValue }%</span>
                </span>
              </p>
            </div>
          </div>
          <div className="bread-action">
            <ul className="info-list">
              <li className="d-inline-block mb-0">
                <a href="#docs">
                  <i
                    className="la la-download"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={ `${Strings.actions.downloadCV}` }
                  ></i>
                </a>
              </li>
              <li className="d-inline-block mb-0">
                <a href={`mailto:${account.contact?.email}`}>
                  <i
                    className="la la-envelope-o"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={ `${Strings.actions.sendMessage}` }
                  ></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="section-block"></div>

        <section className="candidate-details padding-top-30px">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="single-employer-wrap">
                {
                     application?.message && <div className="candidate-description padding-bottom-35px">
                        <h2 className="widget-title">{ Strings.applicantStatus.messageRecruiter }</h2>
                        <div className="title-shape"></div>
                        <p className="mb-3" style={{textAlign:"justify"}}>
                        <i>
                        { application?.message }
                        </i>
                        </p>
                    </div>
                  }
                  <div className="circle-bar-wrap padding-bottom-30px">
                    <h2 className="widget-title">Hard Skills: </h2>
                    <div className="title-shape"></div>
                    <p className="mt-2 font-size-15" style={{ textAlign:"end"}}>
                      <span className="badge p-2 btn-rgb mr-1" >
                        <i
                          className="la la-square-full"
                          style={{ color: "green" }}
                        ></i>
                        { Strings.applicantStatus.skillMatching }
                      </span>
                      <span className="badge p-2 btn-rgb mr-1">
                        <i
                          className="la la-square-full"
                          style={{ color: "red" }}
                        ></i>
                        { Strings.applicantStatus.skillMissing }
                      </span>
                      <span className="badge p-2 btn-rgb mr-1">
                        <i
                          className="la la-square-full"
                          style={{ color: "rgb(60 60 95 / 45%)" }}
                        ></i>
                        { Strings.applicantStatus.skillExtra }
                      </span>
                    </p>
                    <div className="row mt-4" style={{ paddingLeft: 15 }}>
                      <div style={{ display: "contents" }}>
                        {
                         matchedSkills?.filter(x => x.type === 'hard').map((item) => (
                            <SkillCard
                              key={item.id}
                              title={item[Strings.getLanguage()]}
                              badge={item.level}
                              type={item.type}
                              match={item.match}
                            ></SkillCard>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                  <div className="circle-bar-wrap padding-bottom-30px">
                    <h2 className="widget-title">{ Strings.applicantStatus.languages }: </h2>
                    <div className="title-shape"></div>
                    <div className="row mt-4" style={{ paddingLeft: 15 }}>
                        {
                         matchedSkills?.filter(x => x.type === 'language').map((item) => (
                            <SkillCard
                              key={item.id}
                              title={item[Strings.getLanguage()]}
                              badge={item.level}
                              type={item.type}
                              match={item.match}
                            ></SkillCard>
                          ))
                        }
                    </div>
                  </div>

                  <div className="candidate-education padding-bottom-40px">
                    <h2 className="widget-title">{ Strings.applicantStatus.education }</h2>
                    <div className="title-shape"></div>
                    <div className="timeline-dashboard padding-bottom-40px">
                      <div className="timeline-body pt-3">
                        <div className="mess__body">
                            {
                                account?.education?.map((item) => {
                                    return (
                                        <div className="mess__item">
                                        <div className="ring-icon ring-bg-1"></div>
                                        <div className="content">
                                          <p className="color-text-2">
                                            { item.degreeLevel } - { item.program }
                                            <span className="color-text-3">
                                            {'  '}{ moment.unix(item.start/1000).format("MMM/YYYY") } - { item.end ? moment.unix(item.end/1000).format("MMM/YYYY") : '' }
                                            </span>
                                          </p>
                                          <p className="mb-1 font-size-15">
                                          { item.school }
                                          </p>
                                          <span className="time color-text-3 d-block line-height-26">
                                            { Strings.countries[item.location] }
                                          </span>
                                        </div>
                                      </div>
                                    )
                                })
                            }
                        
                         </div>
                      </div>
                    </div>
                   
                    <h2 className="widget-title">{ Strings.applicantStatus.workExperience }</h2>
                    <div className="title-shape"></div>
                    <div className="timeline-dashboard">
                      <div className="timeline-body pt-3">
                        <div className="mess__body">
                            {
                                 account?.experience?.map((item: any) => { 
                                     return (
                                        <div className="mess__item">
                                        <div className="ring-icon ring-bg-1"></div>
                                        <div className="content">
                                          <p className="color-text-2">
                                             { item.role }
                                            <span className="color-text-3">
                                            {'  '}{ moment.unix(item.start/1000).format("MMM/YYYY")  } - { item.end && item.end != -1 ? moment.unix(item.end/1000).format("MMM/YYYY") : Strings.present }
                                            </span>
                                          </p>
                                          <p className="mb-1 font-size-15">{ item.company }</p>
                                          <span className="time color-text-3 d-block line-height-26">
                                          { Strings.countries[item.location] }, { item.employmentType }
                                          </span>
                                        </div>
                                      </div>
                                     )
                                 } )
                            }
                         
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="circle-bar-wrap padding-bottom-30px" id="docs">
                    <h2 className="widget-title">Docs: </h2>
                    <div className="title-shape"></div>
                    <div className="row mt-4" style={{ paddingLeft: 15 }}>
                       <DocumentList documents={[...account?.files]}></DocumentList>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
}

export default AccountView;
