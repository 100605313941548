import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import MainLayout from "./layouts/PrivateLayout";
import StepWizard from "react-step-wizard";
import useAuth from "../hooks/useAuth";
import jobService from "../services/jobs";
import { useHistory, useParams } from "react-router-dom";
import RequiredSkillsTable from "../components/job-ads/required-skills-table";
import skillService from "../services/skills";
import Strings from "../localization/translation";
import { handleRequest } from "../services/api-handler";
import { jobAdStatusType } from "../services/jobs/interface";
import { IValidationModelTypes } from "../util/validation/interface";
import validateModel from "../util/validation";
import { showValidationErrors } from "../util/validation/ui-messages";
import {
  NotificationType,
  showNotificationWithIcon,
} from "../services/notifications";
import {
  SaveOutlined,
  CloseOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import {
  Radio,
  Space,
  Button,
  Drawer,
  Row,
  Col,
  PageHeader,
  Upload,
  Cascader,
  Empty,
  Image,
  Menu
} from "antd";
import {
  workingHoursType,
  durationType,
  employmentType,
  salaryType,
  remoteWorkTypes,
  occupations,
  workExperiences,
  qualifications,
} from "../services/lookup-data";
import { isArray } from "util";
import { getAllLocations } from "services/job-locations";
import SkillsMenu from "components/job-ads/skill-taxanomy";

function JobCreate() {
  const wizardRef = useRef();
  const formGeneralRef = React.createRef();
  const formContactRef = React.createRef();
  const formLocationRef = React.createRef();
  const formSkillsRef = React.createRef();
  const formLanguagesRef = React.createRef();
  const formGeneralConditionsRef = React.createRef();

  let allForms = [
    formGeneralRef,
    formGeneralConditionsRef,
    formSkillsRef,
    formLanguagesRef,
    formLocationRef,
    formContactRef
  ];

  const { id: jobId } = useParams();

  let [changes, setChanges] = useState({});
  let [jobAd, setJobAd] = useState({ cityOrDistrict: "" });
  const { account } = useAuth();
  const { push } = useHistory();
  const [allSkills, setSkills] = useState([]);
  const [jobAdImage, setJobAdImage] = useState();
  let [showDrawer, setShowDrawer] = useState(false);
  const [loading, setLoading] = useState({
    imageUploadLoading: false, 
    jobSaving: false
  })
  useEffect(async () => {
    const [skillsError, fetchedSkills] = await handleRequest(
      skillService.getAllSkills()
    );
    if (fetchedSkills) {
      setSkills(fetchedSkills);
    }
  }, []);

  useEffect(async () => {
    if (jobId) {
      const [jobError, fetchedJobAd] = await handleRequest(
        jobService.getJobById(jobId)
      );

      if (fetchedJobAd) {
        setJobAd(fetchedJobAd);
        setChanges({
          ...fetchedJobAd,
          skills: fetchedJobAd?.skills || [],
          occupationField: {
            jobTechId: (fetchedJobAd?.occupationField?.jobTechId || fetchedJobAd?.occupationField)
          }
        });
      }
    }
  }, []);
  const saveJobAd = async () => {
    try {

      allForms = allForms.filter((item) => item.current !== null);
      delete changes.createdAt;
      delete changes.updatedAt;
      delete changes.files;
      delete changes.company;
      delete changes.coordinates;

      let createdJobAdId = null;
      if (jobAd.id == null) {
        changes.companyId = account?.company?.id;
        changes.status = jobAdStatusType.DRAFT;
        if (changes.skills && changes.skills.length === 0)
          delete changes.skills;
        if (Object.values(changes).length === 0) return;

        const errors = validateModel(
          changes,
          IValidationModelTypes.CREATE_JOBAD
        );

        if (showValidationErrors(allForms, errors)) {
          showNotificationWithIcon(
            Strings.errors.jobSaveValidationError,
            "",
            NotificationType.ERROR
          );
          return
        };

        changes.isFromDashboard = true;
        const [createJobAdError, createdJobId] = await handleRequest(
          jobService.createJobAd(changes)
        );
        if (createdJobId) {
          createdJobAdId = createdJobId;
          setJobAd({
            ...jobAd,
            id: createdJobId,
          });
          showNotificationWithIcon(
            Strings.success.jobCreated,
            "",
            NotificationType.SUCCESS
          );

        } else if (createJobAdError) {
          if(createJobAdError.code === 400)
             showValidationErrors(allForms, createJobAdError.message, false);
          if(createJobAdError.code === 404)
            showNotificationWithIcon(
              Strings.errors.errorTitle,
              createJobAdError.message,
              NotificationType.ERROR
            );
        }
      } 
      if(jobId) {
        delete changes.id;
        if (changes?.skills == null || changes?.skills?.length === 0)
          delete changes.skills;
        if (!changes || Object.keys(changes).length === 0) {
          // also compare jobAd skills and changes skills...
          showNotificationWithIcon(
            Strings.success.jobSaved,
            "",
            NotificationType.SUCCESS
          );
          return;
        }

        const errors = validateModel(changes, IValidationModelTypes.EDIT_JOBAD);
        if (showValidationErrors(allForms, errors)) return;

        const [updateJobError, updateJobAdSuccess] = await handleRequest(
          jobService.updateJobAd(jobId, 
            { ...changes, 
              skills: changes?.skills?.filter(x => x.nextLayer) || [],
              isFromDashboard: true
            })
        );
        if (updateJobAdSuccess) {
          showNotificationWithIcon(
            Strings.success.jobSaved,
            "",
            NotificationType.SUCCESS
          );
        }
        if (updateJobError) {
          showNotificationWithIcon(
            Strings.errors.jobSaveError,
            "",
            NotificationType.ERROR
          );
        }
      }

      if(createdJobAdId)
        push(`/`);

    } catch (error) {
      console.log(error, "some kind of unexpected error...", error);
    }
  };

  const uploadJobAdBrandingImage = async () => {
    if (jobAdImage) {
      setLoading({
        ...loading,
        imageUploadLoading: true
      })
      const [uploadJobAdImageError, uploadJobAdImageSuccess] =
        await handleRequest(updateJobAdImage(jobAdImage, jobAd.id));

      if (uploadJobAdImageSuccess) {
        setShowDrawer(false);
        showNotificationWithIcon(
          "Job image saved successfully",
          "",
          NotificationType.SUCCESS
        );
        setJobAdImage(null);
        setLoading({
          ...loading,
          imageUploadLoading: false
        })

        return true
      }
      if(uploadJobAdImageError) return false
    }
    return true
  };

  const addSelectedSkill = (newSkills) => {
    if (newSkills.length > 0) {
      const existsAlready = changes?.skills?.some(
        (x) => x.id == newSkills[0].id
      );
      if (!existsAlready) {
        if (!changes?.skills) changes.skills = [];
        setChanges({
          ...changes,
          skills: [
            ...changes?.skills,
            {
              ...newSkills[0],
              level: 3,
            },
          ],
        });
        showNotificationWithIcon(Strings.success.skillAdded, '', NotificationType.SUCCESS)
      }
    }
  };

  const onSelectedSkillsListUpdated = (updatedSkills, type) => {
    if (updatedSkills) {
      const newSkillList = 
        [...changes?.skills?.filter(x => x.type !== type),
          ...updatedSkills]

      setChanges((previousState) => ({
        ...previousState,
        skills: newSkillList,
      }));
    }
  };

  const updateJobAdImage = (file, jobAdId) => {
    const jobImageFormData = new FormData();
    jobImageFormData.append("file", file);
    jobImageFormData.append("fileName", "jobImage");
    jobImageFormData.append("adId", jobAdId);

    return jobService.updateJobAdBackgroundImage(jobImageFormData);
  };

  return (
    <>
      <MainLayout>
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb-content d-flex flex-wrap justify-content-between align-items-center">
              <div className="section-heading">
                <h2 className="sec__title">  {Strings.jobAd.form.postAJobTitle }</h2>
              </div>
              <ul className="list-items d-flex align-items-center">
                <li className="active__list-item">
                  <a href="/">{Strings.breadcrumbs.home }</a>
                </li>
                <li className="active__list-item">
                  <a href="/jobs">{Strings.breadcrumbs.jobs }</a>
                </li>
                <li>{Strings.jobAd.form.postAJobTitle }</li>
              </ul>
            </div>
          </div>
        </div>
                <div style={{ paddingLeft: 20, paddingRight: 20 }}>
          <StepWizard ref={wizardRef}>
            <>
              <div className="row mt-5" id="validateForm">
                <div className="col-lg-12">
                  <div className="billing-form-item">
                    <div className="billing-title-wrap">
                      <h3 className="widget-title pb-0">{ Strings.jobAd.form.generalInformation }</h3>
                      <div className="title-shape margin-top-10px"></div>
                    </div>
                    <div className="billing-content">
                      <div className="contact-form-action">
                        <form name="formGeneralRef" ref={formGeneralRef}>
                          <div className="row">
                            <div className="col-lg-4 column-lg-full">
                              <div className="input-box">
                                <label className="label-text">{ Strings.jobAd.form.title }</label>
                                <div className="form-group">
                                  <span className="la la-briefcase form-icon"></span>
                                  <input
                                    className="form-control show-error"
                                    type="text"
                                    name="title"
                                    data-name="title"
                                    placeholder="Enter job title"
                                    value={changes?.title}
                                    onChange={(input) =>
                                      setChanges({
                                        ...changes,
                                        title: input.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 column-lg-full">
                              <div className="input-box">
                                <label className="label-text">{ Strings.jobAd.form.position }</label>
                                <div className="form-group">
                                  <span className="la la-briefcase form-icon"></span>
                                  <input
                                    className="form-control  show-error"
                                    type="text"
                                    name="position"
                                    data-name="position"
                                    placeholder="Ex.: Software Engineer"
                                    value={changes?.position}
                                    onChange={(input) =>
                                      setChanges({
                                        ...changes,
                                        position: input.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 column-lg-full">
                                      <div className="input-box">
                                        <label className="label-text">
                                        { Strings.jobAd.form.occupationField }
                                        </label>
                                        <div className="form-group">
                                          <select
                                            className="form-control show-error"
                                            name="occupationField"
                                            data-name="occupationField"
                                            onChange={(input) => 
                                              setChanges({
                                                ...changes,
                                                occupationField:{
                                                  jobTechId: input.target.value
                                                }
                                              })
                                            }
                                            value={ changes?.occupationField?.jobTechId }
                                          >
                                            <option value="" key="noOccupation"></option>
                                           {
                                             occupations && occupations.map((item, index) => {
                                               return <option value={item.id} key={item.id}> { item[Strings.getLanguage()] }   </option>
                                             })
                                           }
                                            
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                            <div className="col-lg-4 column-lg-full">
                              <div className="input-box">
                                <label className="label-text">{ Strings.jobAd.form.reference }</label>
                                <div className="form-group">
                                  <span className="la la-briefcase form-icon"></span>
                                  <input
                                    className="form-control show-error"
                                    type="text"
                                    name="reference"
                                    data-name="reference"
                                    placeholder=""
                                    value={
                                      changes?.reference
                                    }
                                    onChange={(input) =>
                                      setChanges({
                                        ...changes,
                                        reference: input.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 column-lg-full">
                              <div className="input-box">
                                <label className="label-text">
                                { Strings.jobAd.form.numberOfVacancies }
                                </label>
                                <div className="form-group">
                                  <input
                                    id="numberOfVacancies"
                                    name="numberOfVacancies"
                                    data-name="numberOfVacancies"
                                    className="form-control show-error"
                                    type="number"
                                    min={1}
                                    onChange={(input) =>
                                      setChanges({
                                        ...changes,
                                        numberOfVacancies: input.target.value,
                                      })
                                    }
                                    value={
                                      changes?.numberOfVacancies
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-4 column-lg-full">
                              <div className="input-box">
                                <label className="label-text">
                                { Strings.jobAd.form.deadline }
                                </label>
                                <div className="form-group">
                                  <span className="la la-calendar form-icon"></span>
                                  <input
                                    className="date-range form-control show-error"
                                    type="date"
                                    name="deadline"
                                    data-name="deadline"
                                    placeholder="yyyy-mm-dd"
                                    value={changes?.deadline}
                                    onChange={(input) =>
                                      setChanges({
                                        ...changes,
                                        deadline: input.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-4 column-lg-full">
                              <div className="input-box">
                                <label className="label-text">
                                { Strings.jobAd.form.externalApplicationUrl }
                                </label>
                                <div className="form-group">
                                  <input
                                    className="form-control show-error"
                                    type="url"
                                    name="externalApplicationUrl"
                                    data-name="fetchedFrom"
                                    value={
                                      changes?.externalApplicationUrl
                                    }
                                    onChange={(input) =>
                                      setChanges({
                                        ...changes,
                                        externalApplicationUrl: input.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="billing-form-item">
                            <div className="billing-title-wrap">
                              <h3 className="widget-title pb-0">
                              { Strings.jobAd.form.generalConditions }
                              </h3>
                              <div className="title-shape margin-top-10px"></div>
                            </div>
                            <div className="billing-content">
                              <div className="contact-form-action">
                                <form
                                  method="post"
                                  name="formGeneralConditionsRef"
                                  ref={formGeneralConditionsRef}
                                >
                                  <div className="row">
                                    <div className="col-lg-4 column-lg-full">
                                      <div className="input-box">
                                        <label className="label-text">
                                        { Strings.jobAd.form.experience }
                                        </label>
                                        <div className="form-group">
                                          <select
                                            className="form-control show-error"
                                            name="experienceLevel"
                                            data-name="experienceLevel"
                                            onChange={(input) =>
                                              setChanges({
                                                ...changes,
                                                experienceLevel:
                                                  input.target.value,
                                              })
                                            }
                                            value={
                                              changes?.experienceLevel
                                            }
                                          >
                                            <option value="" key="noExperience"></option>
                                          {
                                            workExperiences.map(item => {
                                              return (<option value={`${item.id}`} key={item.id}>
                                                        { item[Strings.getLanguage()] }
                                                      </option>)
                                            })
                                          }
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-4 column-lg-full">
                                      <div className="input-box">
                                        <label className="label-text">
                                        { Strings.jobAd.form.qualifationType }
                                        </label>
                                        <div className="form-group">
                                          <select
                                            className="form-control show-error"
                                            onChange={(input) =>
                                              setChanges({
                                                ...changes,
                                                educationLevel:
                                                  input.target.value,
                                              })
                                            }
                                            value={
                                              changes?.educationLevel
                                            }
                                            name="educationLevel"
                                            data-name="educationLevel"
                                          >
                                            <option value="" key="noQualificationType"></option>
                                           {
                                            qualifications.map(item => {
                                              return (<option value={`${item.id}`} key={`${item.id}`} >
                                                        { item[Strings.getLanguage()] }
                                                      </option>)
                                            })
                                          }
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div className="col-lg-4 column-lg-full"></div> */}
                                    <div className="col-lg-4 column-lg-full">
                                      <div className="input-box">
                                        <label className="label-text">
                                        { Strings.jobAd.form.workingHoursType }
                                        </label>
                                        <div className="form-group">
                                          <Radio.Group
                                            name="workingHoursType"
                                            onChange={(input) =>
                                              setChanges({
                                                ...changes,
                                                workingHoursType:
                                                  input.target.value,
                                              })
                                            }
                                            value={
                                              changes?.workingHoursType
                                            }
                                          >
                                            <Space direction="horizontal">
                                              {Object.entries(
                                                workingHoursType
                                              ).map((item) => {
                                                return (
                                                  <Radio value={item[0]} key={item[0]}>
                                                    {
                                                      item[1][
                                                        Strings.getLanguage()
                                                      ]
                                                    }
                                                  </Radio>
                                                );
                                              })}
                                            </Space>
                                          </Radio.Group>
                                          <span
                                            className="show-error"
                                            data-name="workingHoursType"
                                          ></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-4 column-lg-full">
                                      <div className="input-box">
                                        <label className="label-text">
                                        { Strings.jobAd.form.employmentType }
                                        </label>
                                        <div className="form-group">
                                          <Radio.Group
                                            name="employmentType"
                                            onChange={(input) =>
                                              setChanges({
                                                ...changes,
                                                employmentType:
                                                  input.target.value,
                                              })
                                            }
                                            value={
                                              changes?.employmentType
                                            }
                                          >
                                            <Space direction="vertical">
                                              {Object.entries(
                                                employmentType
                                              ).map((item) => {
                                                return (
                                                  <Radio value={item[0]}  key={item[0]} >
                                                    {
                                                      item[1][
                                                        Strings.getLanguage()
                                                      ]
                                                    }
                                                  </Radio>
                                                );
                                              })}
                                            </Space>
                                          </Radio.Group>
                                          <span
                                            className="show-error"
                                            data-name="employmentType"
                                          ></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-4 column-lg-full">
                                      <div className="input-box">
                                        <label className="label-text">
                                        { Strings.jobAd.form.duration }
                                        </label>
                                        <div className="form-group">
                                          <Radio.Group
                                            name="duration"
                                            onChange={(input) =>
                                              setChanges({
                                                ...changes,
                                                duration: input.target.value,
                                              })
                                            }
                                            value={
                                              changes?.duration
                                            }
                                          >
                                            <Space direction="vertical">
                                              {Object.entries(durationType).map(
                                                (item) => {
                                                  return (
                                                    <Radio value={item[0]} key={item[0]} >
                                                      {
                                                        item[1][
                                                          Strings.getLanguage()
                                                        ]
                                                      }
                                                    </Radio>
                                                  );
                                                }
                                              )}
                                            </Space>
                                          </Radio.Group>
                                          <span
                                            className="show-error"
                                            data-name="duration"
                                          ></span>
                                        </div>
                                      </div>
                                    </div>

                                    {/* <div className="col-lg-4 column-lg-full">
                              <div className="input-box">
                                <label className="label-text">Scope of Work</label>
                                <div className="form-group">
                                  <input
                                    className="form-control"
                                    name="scopeOfWork"
                                    onChange={(input) =>
                                      setChanges({
                                        ...changes,
                                        scopeOfWork: {
                                          ...changes.duration,
                                          en: input.target.value,
                                          sv: input.target.value
                                        },
                                      })
                                    }
                                    value={changes?.scopeOfWork ? changes?.scopeOfWork[Strings.getLanguage()] : ""}>
                                    </input>
                                </div>
                              </div>
                            </div> */}
                                    <div className="col-lg-4 column-lg-full">
                                      <div className="input-box">
                                        <label className="label-text">
                                        { Strings.jobAd.form.salaryType }
                                        </label>
                                        <div className="form-group">
                                          <Radio.Group
                                            name="salaryType"
                                            onChange={(input) =>
                                              setChanges({
                                                ...changes,
                                                salaryType: input.target.value,
                                              })
                                            }
                                            value={
                                              changes?.salaryType
                                            }
                                          >
                                            <Space direction="vertical">
                                              {Object.entries(salaryType).map(
                                                (item) => {
                                                  return (
                                                    <Radio value={item[0]} key={item[0]} >
                                                      {
                                                        item[1][
                                                          Strings.getLanguage()
                                                        ]
                                                      }
                                                    </Radio>
                                                  );
                                                }
                                              )}
                                            </Space>
                                          </Radio.Group>
                                          <span
                                            className="show-error"
                                            data-name="salaryType"
                                          ></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="input-box">
                                        <label className="label-text">
                                        { Strings.jobAd.form.tasks }
                                        </label>
                                        <div className="form-group mb-0">
                                          <span className="la la-pencil form-icon"></span>
                                          <textarea
                                            className="form-control"
                                            name="responsibilityTasks"
                                            placeholder={`${Strings.jobAd.form.jobDescriptionPlaceHolder }`}
                                            value={
                                              changes?.responsibilityTasks
                                            }
                                            onChange={(input) =>
                                              setChanges({
                                                ...changes,
                                                responsibilityTasks:
                                                  input.target.value,
                                              })
                                            }
                                          ></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                    
                  <div className="billing-form-item">
                            <div className="billing-title-wrap">
                              <h3 className="widget-title pb-0">{ Strings.jobAd.form.hardSkillsTitle }</h3>
                              <div className="title-shape margin-top-10px"></div>
                            </div>
                            <div className="billing-content">
                              <div className="contact-form-action">
                                <div className="row margin-bottom-20px">
                                  <div className="col-lg-12">
                                    <Typeahead
                                      id="dropdownSkills"
                                      maxResults={20}
                                      options={allSkills.filter(
                                        (item) => item.type === "hard"
                                      )}
                                      labelKey={Strings.getLanguage()}
                                      paginate={true}
                                      placeholder={`${Strings.jobAd.form.skillsPickTitle }`}
                                      clearButton
                                      filterBy={[Strings.getLanguage()]}
                                      onChange={(e) => addSelectedSkill(e)}
                                      data-cy="skill-search"
                                      className="dropdownSkills"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12"  style={{ height: 400, overflow: "auto"}}>
                                    <div>
                                      {allSkills && allSkills.length > 0 && <SkillsMenu onSkillSelected={(skill) => addSelectedSkill([skill])} skills={allSkills}></SkillsMenu>}
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <div className="table-responsive">
                                      <RequiredSkillsTable
                                        skills={changes?.skills?.filter(
                                          (item) => item.type === "hard"
                                        )}
                                        onListUpdated={(skills) =>
                                          onSelectedSkillsListUpdated(
                                            skills,
                                            "hard"
                                          )
                                        }
                                      ></RequiredSkillsTable>
                                      {(changes?.skills == null || changes?.skills?.filter(x => x.type == 'hard').length <= 2) && <Empty
                                        description={
                                          <>
                                            <form  ref={formSkillsRef} name="formSkillsRef">
                                              <span className="show-error" data-name="skills">
                                              { Strings.jobAd.form.emptySkillsDescription }
                                              </span>
                                            </form>
                                            
                                          </>
                                        }
                                      />}
                                    </div>
                                  </div>
                                </div>
                               
                              </div>
                            </div>
                          </div>
                       
                  <div className="billing-form-item">
                            <div className="billing-title-wrap">
                              <h3 className="widget-title pb-0">{ Strings.jobAd.form.languageSkillsTitle }</h3>
                              <div className="title-shape margin-top-10px"></div>
                            </div>
                            <div className="billing-content">
                              <div className="contact-form-action">
                                <div className="row margin-bottom-20px">
                                  <div className="col-lg-12" id="dropdownLanguageSkills">
                                    <Typeahead
                                      id="dropdownLanguageSkills"
                                      maxResults={20}
                                      options={allSkills.filter(
                                        (item) => item.type === "language"
                                      )}
                                      labelKey={Strings.getLanguage()}
                                      paginate={true}
                                      placeholder={`${Strings.jobAd.form.pickALanguage }`}
                                      clearButton
                                      filterBy={[Strings.getLanguage()]}
                                      onChange={(e) => addSelectedSkill(e)}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="table-responsive">
                                      <RequiredSkillsTable
                                        skills={changes?.skills?.filter(
                                          (item) => item.type === "language"
                                        )}
                                        onListUpdated={(skills) =>
                                          onSelectedSkillsListUpdated(
                                            skills,
                                            "language"
                                          )
                                        }
                                      ></RequiredSkillsTable>
                                      {(changes?.skills == null || changes?.skills?.filter(x=> x.type == 'language').length < 1) && <Empty
                                        description={
                                          <>
                                           <form  ref={formLanguagesRef} name="formLanguagesRef">
                                           <span className="show-error" data-name="skills">
                                           { Strings.jobAd.form.emptyLanguageSkillsDescription }
                                            </span>
                                           </form>
                                          
                                          </>
                                        }
                                      />}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                         
                  <div className="billing-form-item">
                            <div className="billing-title-wrap">
                              <h3 className="widget-title pb-0">
                              { Strings.jobAd.form.workLocationTitle }
                              </h3>
                              <div className="title-shape margin-top-10px"></div>
                            </div>
                            <div className="billing-content">
                              <div className="contact-form-action">
                                <form
                                  method="post"
                                  name="formContactRef"
                                  ref={formContactRef}
                                >
                                  <div className="row">
                                    <div className="col-lg-3">
                                      <div className="input-box">
                                        <label className="label-text">
                                        { Strings.jobAd.form.remoteWork }
                                        </label>
                                        <div className="form-group">
                                          <select
                                            className="form-control"
                                            value={
                                              changes?.remoteWork
                                            }
                                            onChange={(input) =>
                                              setChanges({
                                                ...changes,
                                                remoteWork: input.target.value,
                                              })
                                            }
                                            name="remoteWork"
                                          >
                                            {Object.entries(
                                              remoteWorkTypes
                                            ).map((item) => {
                                              return (
                                                <option value={item[0]} key={item[0]} >
                                                  {
                                                    item[1][
                                                      Strings.getLanguage()
                                                    ]
                                                  }
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="input-box">
                                        <label className="label-text">
                                        { Strings.forms.city } ({jobAd?.location && isArray(jobAd?.location) && jobAd?.location.map(x =>`-> ${x} `)})
                                        </label>
                                      </div>
                                      <div className="form-group">
                                        <Cascader
                                        style={{ width: "100%"}}
                                          expandTrigger="hover"
                                          allowClear={true}
                                          size={"large"}
                                          autoComplete={true}
                                          showSearch={true}
                                          changeOnSelect={true}
                                          fieldNames={{
                                            label: Strings.getLanguage(),
                                            value: "pk",
                                          }}
                                          options={getAllLocations()}
                                          defaultValue={[changes?.location]}
                                          onChange={(input, selectedOptions) => {   
                                         
                                            if(selectedOptions[1]){
                                              changes.geoSubLayer1 = selectedOptions[1].pk
                                            }
                                            if(selectedOptions[2]){
                                              changes.geoSubLayer2 = selectedOptions[2].pk
                                            }
                                           
                                            const selectedValues = selectedOptions.map(x => {
                                              return x.en
                                            })

                                            setChanges({
                                              ...changes,
                                              location: selectedValues
                                            })
                                           }
                                          }
                                          placeholder="Please select"
                                        />
                                         <span
                                            className="show-error"
                                            data-name="geoSubLayer1"
                                          ></span>
                                      </div>
                                    </div>
                                    <div className="col-lg-3">
                                      <div className="input-box">
                                        <label className="label-text">
                                        { Strings.forms.address }
                                        </label>
                                        <div className="form-group">
                                          <span className="la la-map form-icon"></span>
                                          <input
                                            className="form-control"
                                            type="address"
                                            name="address"
                                            placeholder=""
                                            value={
                                              changes?.address
                                            }
                                            onChange={(input) =>
                                              setChanges({
                                                ...changes,
                                                address: input.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                      
                   <div className="billing-form-item">
                            <div className="billing-title-wrap">
                              <h3 className="widget-title pb-0">
                              { Strings.jobAd.form.additionalInformationTitle }
                              </h3>
                              <div className="title-shape margin-top-10px"></div>
                            </div>
                            <div className="billing-content">
                              <div className="contact-form-action">
                                <div className="col-lg-12">
                                  <div className="input-box">
                                    <label className="label-text">
                                    { Strings.jobAd.form.benefits }

                                    </label>
                                    <div className="form-group mb-0">
                                      <span className="la la-pencil form-icon"></span>
                                      <textarea
                                        className="form-control"
                                        name="benefits"
                                    placeholder={`${ Strings.jobAd.form.benefitsPlaceholder }`}
                                        value={
                                          changes?.benefits
                                        }
                                        onChange={(input) =>
                                          setChanges({
                                            ...changes,
                                            benefits: input.target.value,
                                          })
                                        }
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-12">
                                  <div className="input-box">
                                    <label className="label-text">
                                    { Strings.jobAd.form.teaserText }
                                    </label>
                                    <div className="form-group mb-0">
                                      <span className="la la-pencil form-icon"></span>
                                      <textarea
                                        className="form-control"
                                        name="freeText"
                                        placeholder={`${ Strings.jobAd.form.teaserTextPlaceholder }`}
                                        value={
                                          changes?.freeText
                                        }
                                        onChange={(input) =>
                                          setChanges({
                                            ...changes,
                                            freeText: input.target.value,
                                          })
                                        }
                                      >
                                        {changes?.freeText}
                                      </textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> 

                 <div className="billing-form-item">
                            <div className="billing-title-wrap">
                              <h3 className="widget-title pb-0">
                              { Strings.jobAd.form.contactPersonTitle }
                              </h3>
                              <div className="title-shape margin-top-10px"></div>
                            </div>
                            <div className="billing-content">
                              <div className="contact-form-action">
                                <form
                                  method="post"
                                  name="formLocationRef"
                                  ref={formLocationRef}
                                >
                                  <div className="row">
                                    <div className="col-lg-4">
                                      <div className="input-box">
                                        <label className="label-text">
                                        { Strings.jobAd.form.contactPersonTitle }
                                        </label>
                                        <div className="form-group">
                                          <span className="la la-briefcase form-icon"></span>
                                          <input
                                            className="form-control"
                                            type="text"
                                            name="contactPersonTitle"
                                            placeholder="Ex.: HR Manager"
                                            value={
                                              changes?.contactPersonTitle
                                            }
                                            onChange={(input) =>
                                              setChanges({
                                                ...changes,
                                                contactPersonTitle:
                                                  input.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-8">
                                      <div className="input-box">
                                        <label className="label-text">
                                        { Strings.jobAd.form.contactPersonName }
                                        </label>
                                        <div className="form-group">
                                          <span className="la la-user form-icon"></span>
                                          <input
                                            className="form-control"
                                            type="text"
                                            name="contactPersonName"
                                            placeholder=""
                                            value={
                                              changes?.contactPersonName
                                            }
                                            onChange={(input) =>
                                              setChanges({
                                                ...changes,
                                                contactPersonName:
                                                  input.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-4">
                                      <div className="input-box">
                                        <label className="label-text">
                                        { Strings.jobAd.form.contactPersonPhone }
                                        </label>
                                        <div className="form-group">
                                          <span className="la la-phone form-icon"></span>
                                          <input
                                            className="form-control"
                                            type="phone"
                                            name="contactPersonPhone"
                                            placeholder="+46 712345678"
                                            value={
                                              changes?.contactPersonPhone
                                            }
                                            onChange={(input) =>
                                              setChanges({
                                                ...changes,
                                                contactPersonPhone:
                                                  input.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-8">
                                      <div className="input-box">
                                        <label className="label-text">
                                        { Strings.jobAd.form.contactPersonEmail }
                                        </label>
                                        <div className="form-group">
                                          <span className="la la-envelope form-icon"></span>
                                          <input
                                            className="form-control show-error"
                                            data-name="contactPersonEmail"
                                            type="email"
                                            name="contactPersonEmail"
                                            placeholder=""
                                            value={
                                              changes?.contactPersonEmail
                                            }
                                            onChange={(input) =>
                                              setChanges({
                                                ...changes,
                                                contactPersonEmail:
                                                  input.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                </div>
              </div>
            </>
          </StepWizard>
        </div>
        <div
          className="row"
          style={{
            position: "fixed",
            bottom: 0,
            zIndex: 2000,
            width: "100%",
            boxShadow: "10px 0 10px #ddd",
            borderTop: "1px solid #ddd",
            backgroundColor: "white",
          }}
        >
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6">
                <Button
                   
                    icon={<CloseOutlined />}
                    className="m-2"
                    size={"middle"}
                    onClick={() => push("/")}
                    danger
                  >
                   { Strings.jobAd.form.cancelButton }
                </Button>

                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  className="m-2"
                  size={"middle"}
                  onClick={() => saveJobAd()}
                  data-cy="btSubmit"
                >
                   { Strings.jobAd.form.saveButton }
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Drawer
          title="Preview of Job Ad."
          placement={"right"}
          closable={false}
          onClose={() => setShowDrawer(false)}
          visible={false}
          key={"preview"}
          zIndex={3000}
          width={820}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button
                onClick={() => {
                  setShowDrawer(false);
                  setJobAdImage(null);
                }}
                style={{ marginRight: 8 }}
              >
                Cancel
              </Button>
              {(
                <Button
                  onClick={() =>setShowDrawer(false) }
                  type="primary"
                  className="theme-btn btn"
                  loading={loading.imageUploadLoading}
                >
                  Save and Close
                </Button>
              )}
            </div>
          }
        >
          <Row style={{ justifyContent: "center" }}>
            { (
              <Col span={12} style={{ alignSelf: "center" }}>
                <PageHeader
                  className="site-page-header"
                  title="Branding"
                  subTitle="upload your own image"
                />
                <div
                  style={{
                    alignItems: "center",
                    verticalAlign: "center",
                    padding: 20,
                  }}
                >
                  <Upload.Dragger
                    name="jobImage"
                    id="filer_input"
                    accept="image/*"
                    multiple={false}
                    beforeUpload={(file, fileList) => {
                      setJobAdImage(file);
                      return false;
                    }}
                    onRemove={() => {
                      setJobAdImage(null);
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">Size: 500-600 X 800 - 900</p>
                  </Upload.Dragger>
                  <div style={{ paddingTop: 20, height: 100 }}>
                    
                  </div>
                </div>
              </Col>
            )}
            <Col span={12} style={{ alignItems: "center" }}>
              <div
                style={{
                  height: "75vh",
                  position: "relative",
                  alignItems: "center",
                  textAlign: "center",
                  border: "15px solid  #d9d9d9",
                  borderRadius: 18
                }}>
                <Image
                
                      src={
                        jobAdImage != null
                          ? URL.createObjectURL(jobAdImage)
                          : `${
                              jobAd?.files?.length == null ||
                              jobAd?.files?.length == 0
                                ? ""
                                : jobAd?.files[0]?.url
                            }?v=${Math.random() * 1000}`
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        alignSelf: "center",
                        
                      }}
                    />
              </div>
            </Col>
          </Row>
        </Drawer>
      </MainLayout>
    </>
  );
}

export default JobCreate;
