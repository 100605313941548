import { Alert } from 'antd';
import  { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import accountService from 'services/accounts';
import { handleRequest } from 'services/api-handler';
import { NotificationType, showNotificationWithIcon } from 'services/notifications';
import AuthLayout from './layouts/PublicLayout';

function Activation () {
     const { token, code } = useParams();
     const [ activationStatus, setActvationStatus ] = useState(null)
     const { push } = useHistory()
     
     useEffect(() => {
            confirmEmail(token, code);
     }, [token]);

     const confirmEmail = async (token, code) => {
        const [accountConfirmedError, accountConfirmed] = await handleRequest(
            accountService.confirmEmail(token, code)
          );
          if(accountConfirmedError){
              setActvationStatus({
                  type: 'error',
                  message: accountConfirmedError.message
              })
              showNotificationWithIcon("Error while activating account", accountConfirmedError.message, NotificationType.ERROR);
          }
          if(accountConfirmed){
             showNotificationWithIcon("Account Activated Successfully!", "", NotificationType.SUCCESS);
             setActvationStatus({
                type: 'success',
                message: 'Account Activated Successfully!'
            })
             push("/login")
          }
     }
        return (
           <>
            <AuthLayout>
                <div className="row">
                    <div className="col-lg-12">
                    <div className="breadcrumb-content d-flex flex-wrap justify-content-between align-items-center">
                        <div className="section-heading">
                        <h2 className="sec__title line-height-45">
                            Welcome, Employchain!
                        </h2>
                        </div>
                        <ul className="list-items d-flex align-items-center">
                        <li className="active__list-item">
                            <a href="#!">Accounts</a>
                        </li>
                        <li className="active__list-item">Activation</li>
                        </ul>
                    </div>
                    </div>
                </div>
                <section className="form-shared padding-top-50px padding-bottom-100px">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mx-auto">
                               {
                               activationStatus && <Alert message={`${ activationStatus.message }`} type={`${ activationStatus.type === 'error' ? 'error' : 'success' }`} showIcon />}
                            </div>
                        </div>
                    </div>
                </section>
            </AuthLayout>
    </>
)
}
export default Activation;