import { createRef, useState } from "react";
import accountService from "../services/accounts";
import { ICompanyAccount, ICredential } from "../services/accounts/interface";
import companyService from "../services/companies";
import useToken from "../hooks/useToken";
import { useHistory } from "react-router-dom";
import AuthLayout from "./layouts/PublicLayout";
import { handleRequest } from "../services/api-handler";
import validateModel from "../util/validation";
import { IValidationModelTypes } from "../util/validation/interface";
import { showValidationErrors } from "../util/validation/ui-messages";
import { extendValidationOfCreateAccount } from "../util/validation/schemas/create-account";
import { Alert, Image, Avatar, AlertProps } from "antd";
import { NotificationType, showNotificationWithIcon } from "../services/notifications";
import Strings from "localization/translation";
import authService from "services/auth";
import { analyticsLogger, gtagManager } from "util/log";
import { events } from "util/log/events";

function CompanyAccess() {
  const { push } = useHistory();
  const [companySearchResult, setCompanySearchResult] = useState<any>(null);
  const [companyAccountToCreate, setCompanyAccountToCreate] = useState<any>({ type: 2, country: "" });
  const [login, setLogin] = useState<ICredential>();
  const [loginStatus, setLoginStatus] = useState(null);
  const [signupStatus, setSignupStatus] = useState<AlertProps>();
  const { setToken, setLoggedAccount, } = useToken();

  const loginTabRef = createRef<HTMLAnchorElement>();
  const signupTabRef = createRef<HTMLAnchorElement>();
  const inputOrgNumberRef = createRef<HTMLInputElement>();
  const formLoginRef = createRef<HTMLFormElement>();
  const formSignupRef = createRef<HTMLFormElement>();

  const findCompanyByOrgNumber = async (organisationNumber: string) => {
    setCompanyAccountToCreate((previousState: ICompanyAccount) => ({
      ...previousState,
      companyId: null,
      organisationNumber : organisationNumber
    }))
    if(organisationNumber.length < 11) return;

    const [searchError, foundCompany] = await handleRequest(
      companyService.searchCompany(organisationNumber)
    );
    if (searchError) {
       setCompanySearchResult(null);
       return;
    };

    const logoUrl = foundCompany?.files?.find(x => x.name === 'companyLogo')?.url;
    setCompanySearchResult({
      ...foundCompany,
      logoUrl
    });

    setCompanyAccountToCreate((previousState: ICompanyAccount) => ({
      ...previousState,
      companyId: foundCompany?.id || null,
      organisationNumber
    }))
 };

  const createCompanyAccount = async (accountToCreate: any) => {
    try {
      if(accountToCreate.country !== 'SE') {
        delete accountToCreate.organisationNumber;
        delete accountToCreate.companyId;
      }

      const data = {
        ...accountToCreate,
        type: 2
      }

      const [accountCreateError, accountCreated] = await handleRequest(
        accountService.createAccount(data)
      );

      if (accountCreateError) {
        showNotificationWithIcon('Please verify the data', accountCreateError.message)
        setSignupStatus({
          type: 'error',
          message: accountCreateError?.message
        });
        return;
      }

      if(accountCreated){
        const successMessage = `An email was sent to ${accountToCreate.email} to verify your account.`;
        showNotificationWithIcon('Account Created', successMessage, NotificationType.SUCCESS);

        setSignupStatus({
          type: 'success',
          message: `'Account Created' - ${successMessage}`
        });
        return;
      }     
    } catch (error) {
      console.log("create company account error");
    }
  };

  const handleSubmitCreateAccount = async (accountToCreate: any) => {
      const errors = validateModel(
        accountToCreate,
        IValidationModelTypes.CREATE_ACCOUNT,
        function (errors: any) {
          extendValidationOfCreateAccount(errors, accountToCreate);
        }
      );
      if (showValidationErrors([formSignupRef], errors)) return;
      //move to validations extension method
      if(!accountToCreate.privacyTermsAccepted || !accountToCreate.termsAndConditionsAccepted){
        setSignupStatus({
          type: 'error',
          message: `*${Strings.agreeToPrivacyPolicy} & ${Strings.agreeToTermsAndConditions}`
        });
        return;
      }

      delete accountToCreate.confirmPassword
      delete accountToCreate.privacyTermsAccepted
      delete accountToCreate.termsAndConditionsAccepted
      
      createCompanyAccount(accountToCreate) ;
}

  const handleLogin = async (username?: string, password?: string) => {
    const data = {
      email: username,
      password: password,
    };

    const errors = validateModel(data, IValidationModelTypes.LOGIN);
    if (showValidationErrors([formLoginRef], errors)) return;

    const [loginError, loginToken] = await handleRequest(
      accountService.login(data)
    );
    if (loginError) {
      setLoginStatus({
        type: "Error",
        message: loginError.message
      });
      return;
    }
    setToken(loginToken);
    
    const [accountGetError, accountData] = await handleRequest(
      accountService.getAccount()
      );
      
      if (accountGetError) {
        setLoginStatus({
          type: "Error",
          message: accountGetError.message
        });
        return;
    }
    gtagManager.login({id: accountData.id});
    analyticsLogger.setUserIdentity(accountData.id);
    analyticsLogger.logEvent(events.USER_LOGGED_IN, {test:true})
    if(accountData.type != 2) {
      //send header to backend for validation
      authService.logout(() => {
        console.log("USER_LOGGED_OUT", "PERMISSIONS_DENIED");
      })
    }
    
    const loggedAccount = {
      email: accountData.email,
      emailConfirmed: accountData.emailConfirmed,
      confirmedByCompany: accountData.confirmedByCompany,
      company: accountData.company,
      companyId: accountData.companyId
    };

   
    setLoggedAccount(loggedAccount);
    push("/");
  };

  return (
    <AuthLayout>
      <div className="row">
        <div className="col-lg-12">
          <div className="breadcrumb-content d-flex flex-wrap justify-content-between align-items-center">
            <div className="section-heading">
              <h2 className="sec__title line-height-45">
              {Strings.welcome} 
              </h2>
            </div>
            <ul className="list-items d-flex align-items-center">
              <li className="active__list-item">
                <a href="index.html">{ Strings.breadcrumbs.home } </a>
              </li>
              <li className="active__list-item">{ Strings.breadcrumbs.employers } </li>
              <li>{ Strings.breadcrumbs.signup } </li>
            </ul>
          </div>
        </div>
      </div>
      <section className="form-shared padding-top-50px padding-bottom-100px">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="user-action-form">
                <div className="tab-shared tab-shared-3">
                  <ul
                    className="nav nav-tabs justify-content-center"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link theme-btn active"
                        id="login-tab"
                        data-toggle="tab"
                        href="#login-nav"
                        role="tab"
                        aria-controls="login-nav"
                        aria-selected="true"
                        ref={signupTabRef}
                      >
                        <i className="la la-sign-in"></i>  {Strings.loginTitle} 
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link theme-btn"
                        id="signup-tab"
                        data-toggle="tab"
                        href="#signup-nav"
                        role="tab"
                        aria-controls="signup-nav"
                        aria-selected="false"
                        ref={loginTabRef}
                      >
                        <i className="la la-user"></i> {Strings.registerAccountTitle} 
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="login-nav"
                    role="tabpanel"
                    aria-labelledby="login-tab"
                  >
                    <div className="billing-form-item mb-0">
                      <div className="billing-title-wrap border-bottom-0 text-center">
                        <h3 className="widget-title font-size-28 pb-2">
                        {Strings.loginTitle} 
                        </h3>
                      </div>
                      <div className="billing-content">
                        <div className="contact-form-action">
                          <form
                            method="post"
                            id="formLogin"
                            name="formLogin"
                            ref={formLoginRef}
                          >
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="input-box">
                                  <label className="label-text">
                                  {Strings.forms.email} 
                                  </label>
                                  <div className="form-group">
                                    <i className="la la-user form-icon"></i>
                                    <input
                                      className="form-control login-email"
                                      type="email"
                                      name="email"
                                      data-cy="login-email"
                                      placeholder={`${ Strings.forms.email }`}
                                      onChange={(e) =>
                                        setLogin((prevState) => ({
                                          ...prevState,
                                          email: e.target.value,
                                        }))
                                      }
                                      value={login?.email}
                                    />
                                    {/* <ErrorMessage message={ validationErrors["email"]?.message }></ErrorMessage> */}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="input-box">
                                  <label className="label-text">{Strings.forms.password} </label>
                                  <div className="form-group">
                                    <i className="la la-lock form-icon"></i>
                                    <input
                                      className="form-control"
                                      type="password"
                                      name="password"
                                      data-cy="login-password"
                                      placeholder={`${ Strings.forms.password }`}
                                      onChange={(e) =>
                                        setLogin((prevState) => ({
                                          ...prevState,
                                          password: e.target.value,
                                        }))
                                      }
                                      value={login?.password}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <div className="custom-checkbox mr-0 d-flex align-items-center justify-content-between">
                                    <div>
                                      <input type="checkbox" id="chb1" />
                                      <label htmlFor="chb1">
                                      {Strings.keepMeLoggedIn} 
                                      </label>
                                    </div>
                                    <div>
                                      <a
                                        href="/forgot-password"
                                        className="color-text"
                                      >
                                       {Strings.forgotPasswordTitle } 
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                {loginStatus && loginStatus.type === "Error" && (
                                  <Alert
                                    message={`${loginStatus.message}`}
                                    type="error"
                                    showIcon
                                  />
                                )}
                                {loginStatus && loginStatus.type === "Success" && (
                                  <Alert
                                    message={`${loginStatus.message}`}
                                    type="success"
                                    showIcon
                                  />
                                )}
                                <div className="btn-box margin-top-20px margin-bottom-10px">
                                  <button
                                    data-cy="btLogin"
                                    className="theme-btn border-0"
                                    type="button"
                                    onClick={() =>
                                      handleLogin(login?.email, login?.password)
                                    }
                                  >
                                    {Strings.loginButton} 
                                  </button>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <p>
                                 { Strings.dontHaveAccountTitle }?{" "}
                                  <a
                                    className="color-text "
                                    id="signup-tab"
                                    data-toggle="tab"
                                    href="#signup-nav"
                                    role="tab"
                                    aria-controls="signup-nav"
                                    aria-selected="false"
                                    onClick={() =>
                                      signupTabRef.current?.click()
                                    }
                                  >
                                    <i className="la la-user"></i> {Strings.createAccountTitle}
                                  </a>
                                </p>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="signup-nav"
                    role="tabpanel"
                    aria-labelledby="signup-tab"
                  >
                    <div className="billing-form-item mb-0">
                      <div className="billing-title-wrap border-bottom-0 text-center">
                        <h3 className="widget-title font-size-28 pb-2">
                        {Strings.employerAccountTitle}
                        </h3>
                        <p>
                        {Strings.registerAccountDescription}
                          <strong className="color-text-2 font-weight-medium">
                          {" "} {Strings.alreadyHaveAnyAccount}?{" "}
                            <span>
                            <a
                              className="color-text"
                              data-toggle="tab"
                              href="#login-nav"
                              role="tab"
                              aria-controls="login-nav"
                              aria-selected="true"
                              onClick={() => loginTabRef.current?.click()}
                            >
                              <i className="la la-sign-in"></i> {Strings.loginTitle}
                            </a>
                            </span>
                          </strong>
                        </p>
                      </div>
                      <div className="billing-content">
                        <div className="contact-form-action">
                          <form name="formSignupRef" ref={formSignupRef}>
                            <div className="row">
                            </div>
                            <div className="input-box">
                              <label className="label-text">{Strings.country}*</label>
                              <div className="form-group">
                                <select 
                                className="form-control"
                                  onChange={(e) =>
                                    setCompanyAccountToCreate(
                                      (prevState: ICompanyAccount) => ({
                                        ...prevState,
                                        country: e.target.value,
                                      })
                                    )
                                  }
                                  value={companyAccountToCreate.country}>
                                  <option value="">{ Strings.chooseCountry }</option>           
                                  <optgroup>
                                    {
                                      Object.entries(Strings.countries)
                                      .filter(item => item[0] === "SE" || item[0] === "DE")
                                      .map(item => {
                                        return <option key={`${ item[0] }`} value={`${ item[0] }`}> { item[1] } </option>
                                      })
                                    }
                                  </optgroup>
                                  <optgroup>
                                  {
                                      Object.entries(Strings.countries)
                                      .filter(item => item[0] !== "SE" && item[0] !== "DE")
                                      .map(item => {
                                        return <option key={`${ item[0] }`} value={`${ item[0] }`}> { item[1] } </option>
                                      })
                                    }
                                  </optgroup>
                                </select>
                              </div>
                            </div>
                            {companyAccountToCreate.country === "SE" && (
                              <>
                             <div className="input-box margin-bottom-30px">
                               <Alert message={`${ Strings.importJobsDescription }`} type="info" showIcon style={{fontWeight: "bold"}} />
                             </div>
                             <div className="input-box">
                              <label className="label-text">
                              { Strings.forms.organizationNumber }
                              </label>
                              <div className="form-group">
                              <i className="la la-search form-icon"></i>
                              <input
                                  className="form-control show-error"
                                  type="text"
                                  name="organisationNumber"
                                  data-name="organisationNumber"
                                  ref={inputOrgNumberRef}
                                  placeholder="_ _ _ _ _ - _ _ _ _"
                                  onChange={(e) =>
                                    findCompanyByOrgNumber(`${e.target.value}`)
                                  }
                              />
                              </div>
                            </div>
                            {companySearchResult && <div className="input-box">
                              <div className="form-group center" style={{ textAlign: "center"}}>
                              <Avatar src={<Image src={`${companySearchResult.logoUrl }`} style={{  }} />} />
                                 <span style={{fontWeight: "bold"}}>  { companySearchResult?.name } | { companySearchResult?.cityOrDistrict } { companySearchResult?.organisationNumber }</span>
                              </div>
                            </div>}
                            </>
                            )
                            }

                            <div className="input-box">
                              <label className="label-text">{ Strings.forms.email }</label>
                              <div className="form-group">
                                <i className="la la-envelope-o form-icon"></i>
                                <input
                                  className="form-control"
                                  type="email"
                                  name="email"
                                  placeholder="Enter email address"
                                  onChange={(e) =>
                                    setCompanyAccountToCreate(
                                      (prevState: ICompanyAccount) => ({
                                        ...prevState,
                                        email: e.target.value,
                                      })
                                    )
                                  }
                                  value={companyAccountToCreate.email}
                                />
                              </div>
                            </div>
                            <div className="input-box">
                              <label className="label-text">{ Strings.forms.password }</label>
                              <div className="form-group">
                                <i className="la la-lock form-icon"></i>
                                <input
                                  className="form-control show-error"
                                  type="password"
                                  name="password"
                                  data-name="password"
                                  placeholder={`${ Strings.forms.password }`}
                                  onChange={(e) =>
                                    setCompanyAccountToCreate(
                                      (prevState: ICompanyAccount) => ({
                                        ...prevState,
                                        password: e.target.value,
                                      })
                                    )
                                  }
                                  value={companyAccountToCreate.password}
                                />
                              </div>
                            </div>
                            <div className="input-box">
                              <label className="label-text">
                              { Strings.forms.confirmPassword }
                              </label>
                              <div className="form-group">
                                <i className="la la-lock form-icon"></i>
                                <input
                                  className="form-control show-error"
                                  type="password"
                                  name="confirmPassword"
                                  data-name="confirmPassword"
                                  placeholder={`${ Strings.forms.confirmPassword }`}
                                  onChange={(e) =>
                                    setCompanyAccountToCreate(
                                      (prevState: ICompanyAccount) => ({
                                        ...prevState,
                                        confirmPassword: e.target.value,
                                      })
                                    )
                                  }
                                  value={companyAccountToCreate.confirmPassword}
                                />
                              </div>
                            </div>
                            <div className="input-box margin-top-30px">
                              <div className="form-group">
                                <div className="custom-checkbox d-block mr-0">
                                  <input type="checkbox" className="show-error" name="privacyTermsAccepted" required id="chb3" 
                                  onChange={(e) => {
                                    setCompanyAccountToCreate(
                                      (prevState: ICompanyAccount) => ({
                                        ...prevState,
                                        privacyTermsAccepted: e.target.checked,
                                      })
                                    )
                                  }
                                  }
                                  value={companyAccountToCreate.privacyTermsAccepted} />
                                  <label htmlFor="chb3">
                                  { Strings.iAgreeTo }{" "}
                                    <a
                                      target="_blank"
                                      href={Strings.privacyURl}
                                      className="color-text"
                                    >
                                      { Strings.agreeToPrivacyPolicy }
                                    </a>
                                  </label>
                                </div>
                                <div className="custom-checkbox d-block mr-0">
                                  <input type="checkbox" id="chb4" required name="termsAndConditionsAccepted"
                                   onChange={(e) => {
                                    setCompanyAccountToCreate(
                                      (prevState: ICompanyAccount) => ({
                                        ...prevState,
                                        termsAndConditionsAccepted: e.target.checked,
                                      })
                                    )
                                  }
                                  }
                                  value={companyAccountToCreate.termsAndConditionsAccepted} />
                                  <label htmlFor="chb4">
                                  { Strings.iAgreeTo }{" "}
                                    <a
                                      target="_blank"
                                      href={Strings.termsAndConditionsUrl}
                                      className="color-text"
                                    >
                                     { Strings.agreeToTermsAndConditions }
                                    </a>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="input-box">
                              {signupStatus && (
                                <Alert
                                  message={`${signupStatus.message}`}
                                  type={signupStatus.type === 'error' ? 'error' : 'success'}
                                  showIcon
                                />
                              )}
                            </div>

                            <div className="btn-box margin-top-30px">
                                <button
                                  className="theme-btn border-0"
                                  type="button"
                                  onClick={() =>
                                    handleSubmitCreateAccount(
                                      companyAccountToCreate
                                    )
                                  }>
                                    { Strings.createAccountButton }
                                </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AuthLayout>
  );
}

export default CompanyAccess;
