import { createAccountSchema }   from './schemas/create-account';
import loginSchema   from './schemas/login';
import createJobAdSchema   from './schemas/create-jobad';
import editJobAdSchema   from './schemas/edit-jobad';
import editCompanySchema   from './schemas/edit-company';

export default  {
  createAccount: createAccountSchema,
  login: loginSchema,
  createJobAdSchema: createJobAdSchema,
  editJobAdSchema: editJobAdSchema,
  editCompanySchema: editCompanySchema
};
