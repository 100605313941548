import { Empty } from "antd";
import useAuth from "hooks/useAuth";
import Strings from "localization/translation";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom"
import { jobStatusMessages } from "services/lookup-data";
import { NotificationType, showNotificationWithIcon } from "services/notifications";
import { isArray } from "util";
import { handleRequest } from "../services/api-handler";
import jobService from "../services/jobs";
import { jobAdStatusType } from "../services/jobs/interface";
import MainLayout from './layouts/PrivateLayout';

function JobList() {
  const { push } = useHistory();
  const [jobs, setJobs] = useState<any[]>();
  const [filter, setFilter] = useState<any>({ status: "all", searchText: ""});
  const { account } = useAuth();

  useEffect(() => {
      jobService.getJobAdsOfCurrentAccount()
      .then(resp => {
        let jobs = resp.data
        jobs = jobs.map((item => {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const isExpired = today.getTime() > new Date(item.deadline).getTime() 
          return {
            ...item,
            status: isExpired ? jobAdStatusType.CLOSED: item.status,
            isExpired: isExpired
          }
        }))
        setJobs(jobs);
      })
  }, [])

  const updateJobAdStatus = async (id: string, statusType: jobAdStatusType) => {

    const [updateJobAdStatusError, jobAdStatusUpdated] =  await handleRequest(jobService.updateJobAdStatus(id, statusType));
    if(updateJobAdStatusError) { console.log(updateJobAdStatusError); return;}

    if(jobAdStatusUpdated) {
      const updatedJobsList = jobs?.map((item: any) => {
        if(item.id === id) return { 
          ...item, 
          status: jobAdStatusUpdated.Attributes.status, 
          updatedAt: jobAdStatusUpdated.Attributes.updatedAt }
        return item;
      });

      setJobs(updatedJobsList)
    }
  }

  const togglePublish = (item: any) => {
       if(account.confirmedByCompany !== true) {
         showNotificationWithIcon(Strings.errors.publishingNotAllowedTitle, Strings.errors.publishingNotallowed, NotificationType.ERROR);
         return;
        };
        if(item.isExpired){
          return;
        }
       if(item.published){
        updateJobAdStatus(item.id, jobAdStatusType.CLOSED)
       }
       else{
        updateJobAdStatus(item.id, jobAdStatusType.PUBLISHED)
       }
  }
  const displayStatusIcon = (status: jobAdStatusType) => {
    const { CLOSED, PUBLISHED, DRAFT, TEMPLATE, ARCHIVED } = jobAdStatusType;
    const language = Strings.getLanguage();
    switch (status) {
      case CLOSED: return <span className="badge badge-danger p-1"> {jobStatusMessages.CLOSED.description[language] } <i className="la la-lock"></i></span>
      case PUBLISHED: return <span className="badge badge-success p-1">{jobStatusMessages.PUBLISHED.description[language] }<i className="la la-eye"></i></span>
      case DRAFT: return <span className="badge badge-grey p-1">{jobStatusMessages.DRAFT.description[language] } <i className="la la-history"></i></span>
      case TEMPLATE: return <span className="badge badge-grey p-1"> {jobStatusMessages.TEMPLATE.description[language] } <i className="la la-copy"></i></span>
      case ARCHIVED: return <span className="badge badge-grey p-1"> {jobStatusMessages.ARCHIVED.description[language] } <i className="la la-times"></i></span>
    }
  }

  const filterBy = (filter: any, jobAd: any) => {
    
    let filterBuilder = false;
    if (filter?.status == "all") filterBuilder = true;
    else filterBuilder = filter?.status?.toLowerCase() === jobAd.status
     
    if (filter.searchText && filter?.searchText?.length > 0)
      filterBuilder = filterBuilder && jobAd.title.toLowerCase().includes(filter?.searchText.toLowerCase())

    return filterBuilder;
  };

  const formatJobTitle = (title) => {
    const formattedTitle = title.replace("/", "-");

    return formattedTitle;
  }

    return (
      <>
        <MainLayout>
        <div className="row">
                <div className="col-lg-12">
                  <div className="breadcrumb-content d-flex flex-wrap justify-content-between align-items-center">
                    <div className="section-heading">
                      <h2 className="sec__title">{ Strings.jobAdsManagementTitle } </h2>
                    </div>
                    <ul className="list-items d-flex align-items-center">
                      <li className="active__list-item">
                        <a href="/">{ Strings.breadcrumbs.home }</a>
                      </li>
                      <li className="active__list-item">
                        <a href="/jobs">{ Strings.breadcrumbs.jobs} </a>
                      </li>
                      <li> { Strings.breadcrumbs.list } </li>
                    </ul>
                  </div>
                </div>
              </div>
        <div className="row mt-5" style={{ paddingLeft: 50, paddingRight: 50}}>
          <div className="col-lg-12">
            <div className="billing-form-item">
              <div className="billing-title-wrap">
                <h3 className="widget-title pb-0">{ Strings.manageJobs }</h3>
                <div className="title-shape margin-top-10px"></div>
              </div>
             
              <div className="billing-content pb-0">
                <div className="manage-job-wrap">
                {jobs && jobs?.length > 0 && (
                <div className="manage-job-header mt-3 mb-5">
                    <div className="manage-job-count">
                      <span className="font-weight-medium color-text-2 mr-1">
                        { jobs?.length }
                      </span>
                      <span className="font-weight-medium"> { Strings.jobAd.list.nrJobsPosted }</span>
                    </div>
                    <div className="manage-job-count">
                      {/* <span className="font-weight-medium color-text-2 mr-1">
                        8
                      </span>
                      <span className="font-weight-medium">Application(s)</span> */}
                    </div>
                    <div className="manage-job-count">
                      {/* <span className="font-weight-medium color-text-2 mr-1">
                        { jobs?.length }
                      </span>
                      <span className="font-weight-medium">Active Job(s)</span> */}
                    </div>
                  </div>
                )}
                <div className="table-responsive">
                    { (!jobs || jobs?.length == 0) && (
                      <>
                        <Empty 
                          description={
                            <>
                              <span>
                                { Strings.jobAd.list.emptyJobList } 
                              </span>
                            </>
                          }
                        />
                      </>
                    )
                    
                  
                  }

                  <table className="table">
                      <thead>
                          <tr>
                              <th colSpan={4}>
                                 {jobs && jobs?.length > 0 &&<input type="text" placeholder={`${ Strings.jobAd.list.searchByTitle }`} className="form-control"
                                     onChange={(input) => setFilter({
                                       ...filter,
                                       searchText: input.target.value
                                     }) }
                                     value={filter?.searchText}
                                    />}
                              </th>
                        
                              <th>
                              {jobs && jobs?.length > 0 && <div className="col-sm-12">
                                  <select className="form-control"
                                    onChange={(input) => setFilter({
                                      ...filter,
                                      status: input.target.value
                                    })}
                                    //value={filter?.status}
                                  >
                                    <option value="all">{ Strings.jobAd.list.noFilter }</option>
                                    {
                                      Object.values(jobStatusMessages).map(item => item.title).sort((a,b) => a.id.localeCompare(b.id)).map((item) => {
                                        return <option value={item.id} key={item.id}>{ item[Strings.getLanguage()] }</option>
                                      })
                                    }
                                  </select>
                                </div>
                              }
                               
                              </th>
                              <th colSpan={1}>
                                <div className="col-sm-12">
                                  <button onClick={() => push("/jobs/create")}  className="theme-btn btn-primary" type="button" style={{float: "right"}}>
                                      <i className="la la-plus"></i>  { Strings.jobAd.list.postNewJobAd }
                                  </button>
                                </div>
                              
                              </th>
                          </tr>
                        </thead>
                       </table>

                    {jobs && jobs?.length > 0 && <table className="table">
                      <thead>
                          {/* <tr>
                              <th colSpan={4}>
                                    <input type="text" placeholder="Search by job title..." className="form-control"
                                     onChange={(input) => setFilter({
                                       ...filter,
                                       searchText: input.target.value
                                     }) }
                                     value={filter?.searchText}
                                    />
                              </th>
                        
                              <th>
                                <div className="col-sm-12">
                                  <select className="form-control"
                                    onChange={(input) => setFilter({
                                      ...filter,
                                      status: input.target.value
                                    })}
                                    //value={filter?.status}
                                  >
                                    <option value="all">No filter</option>
                                    {
                                      Object.values(jobAdStatusType).sort((a,b) => a.localeCompare(b)).map((item: any) => {
                                        return <option value={item}>{ item }</option>
                                      })
                                    }
                                  </select>
                                </div>
                               
                              </th>
                              <th colSpan={1}>
                                <div className="col-sm-12">
                                  <button onClick={() => push("/jobs/create")}  className="theme-btn btn-primary" type="button" style={{float: "right"}}>
                                      <i className="la la-plus"></i> Post Job
                                  </button>
                                </div>
                              
                              </th>
                          </tr>
                        */}
                        <tr>
                          <th>{ Strings.jobAd.list.status }</th>
                          <th>{ Strings.jobAd.list.title }</th>
                          <th>{ Strings.jobAd.list.applicants }</th>
                          <th>{ Strings.jobAd.list.expireDate }</th>
                          <th>{ Strings.jobAd.list.type }</th>
                         
                          <th className="text-right">{ Strings.jobAd.list.action }</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          jobs?.filter(x => filterBy(filter, x))
                          .map((x: any) => {
                            return { 
                              ...x, 
                              published: x.status === jobAdStatusType.PUBLISHED 
                            }
                          })
                          .map((item: any) => {
                          return <tr key={item.id}>
                            <td>
                               
                               {
                               (item.status !== jobAdStatusType.TEMPLATE) && <Form.Check 
                                  type="switch"
                                  id={`custom-switch-${item.id}`}
                                  label=""
                                  checked={ item.published }
                                  disabled = {item.isExpired}
                                  onChange={()=> togglePublish(item) }
                                />
                                }
                               
                            </td>
                          <td>
                            <div className="manage-candidate-wrap">
                              <h2 className="widget-title pb-1">
                                <Link
                                  to={{
                                    pathname: `/jobs/${item.id}/${ formatJobTitle(item.title) }/applicants`,
                                    state: { jobTitle: item.title }, // your data array of objects,
                                  }}
                                  className="color-text-2">
                                  { item.title }
                                </Link>
                              </h2>
                              <p>
                                <span>
                                  <i className="la la-map font-size-16"></i>{" "}
                                  { item?.location && isArray(item?.location) && item?.location.map(x => ` ${x}.`) }
                                </span>
                              </p>
                            </div>
                          </td>
                          <td>
                          <Link
                                  to={{
                                    pathname: `/jobs/${item.id}/${item.title.replace(" ", "-")}/applicants`,
                                    state: { jobTitle: item.title }, // your data array of objects,
                                    
                                  }}
                                  className="color-text">
                                     { Strings.jobAd.list.applicants }
                                </Link>
                            </td>
                          <td><i className="la la-clock-o font-size-16"></i> { item.deadline }</td>
                          <td>
                              { displayStatusIcon(item.status) }
                        </td>
                          <td className="text-right">
                            <div className="manage-candidate-wrap">
                              <div className="bread-action pt-0">
                                <ul className="info-list">
                                  <li className="d-inline-block">
                                    <a href={`/jobs/${item.id}/edit`}>
                                      <i
                                        className="la la-edit"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Edit"
                                      ></i>
                                    </a>
                                  </li>
                                  <li className="d-inline-block">
                                    <a href="#!" onClick={() => updateJobAdStatus(item.id, jobAdStatusType.ARCHIVED)}>
                                      <i
                                        className="la la-trash"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Delete"
                                      ></i>
                                    </a>
                                  </li>
                                  {/* <li className="d-inline-block">
                                    <a href="#!" onClick={() => alert("Coming soon")}>
                                      <i
                                        className="la la-copy"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Duplicate"
                                      ></i>
                                    </a>
                                  </li> */}
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                          })
                         }
                        </tbody>
                    </table>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
                <div className="col-lg-12">
                    <div className="page-navigation-wrap mt-4">
                        <div className="page-navigation mx-auto">
                            <a href="#" className="page-go page-prev">
                                <i className="la la-arrow-left"></i>
                            </a>
                            <ul className="page-navigation-nav">
                                <li><a href="#" className="page-go-link">1</a></li>
                                <li className="active"><a href="#" className="page-go-link">2</a></li>
                                <li><a href="#" className="page-go-link">3</a></li>
                                <li><a href="#" className="page-go-link">4</a></li>
                                <li><a href="#" className="page-go-link">5</a></li>
                            </ul>
                            <a href="#" className="page-go page-next">
                                <i className="la la-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div> */}
            </MainLayout>
      </>
    );
}

export default JobList;
