export default {
  properties: {
    companyId: {
      description: '',
      required: true,
      allowEmpty: false,
      type: 'string',
    },

    address: {
      description: '',
      allowEmpty: false,
      type: 'string',
    },
    zipCode: {
      description: '',
      allowEmpty: false,
      type: 'string',
    },
    location: {
      type: 'array',
      properties: {
        type: 'string',
        allowEmpty: false,
      },
    },
    cityOrDistrict: {
      description: '',
      allowEmpty: false,
      type: 'string',
    },
    geoSubLayer1: {
      required: true,
      allowEmpty: false,
      type: 'string',
      messages:{
        required: "Select at least one county"
      }
    },
    geoSubLayer2: {
      description: '',
      allowEmpty: false,
      type: 'string',
    },
    contactPersonName: {
      description: '',
      allowEmpty: false,
      type: 'string',
      
    },
    contactPersonTitle: {
      description: '',
      allowEmpty: false,
      type: 'string',
    },
    contactPersonPhone: {
      description: '',
      allowEmpty: false,
      type: 'string',
    },
    contactPersonEmail: {
      description: '',
      allowEmpty: false,
      type: 'string',
      required: true,
      messages:{
        required: "Contact Person required"
      }
    },

    publicationDate: {
      format: 'date',
      description: '',
      allowEmpty: false,
    },
    deadline: {
      format: 'date',
      description: '',
      allowEmpty: false,
      required: true
    },

    title: {
      description: '',
      required: true,
      allowEmpty: false,
      type: 'string',
      message:{
        en: "Required field",
        sv: "Obligatoriskt fält",
        de: "Pflichtfeld",
      }
    },
    position: {
      description: '',
      required: true,
      allowEmpty: false,
      type: 'string',
    },
    jobType: {
      description: '',
      allowEmpty: false,
      required: true,
      type: 'string',
      enum: [
        'fullTime',
        'partTime',
        'summerJob',
        'internship',
        'traineeship',
        'project',
        'thesis',
      ],
    },
    payment: {
      allowEmpty: false,
      type: 'string',
      enum: ['paid', 'unpaid'],
    },
    responsibilityTasks: {
      description: '',
      allowEmpty: false,
      type: 'string',
    },
    benefits: {
      description: '',
      allowEmpty: false,
      type: 'string',
    },

    educationLevel: {
      description: '',
      allowEmpty: false,
      required: true,
      type: 'string',
      enum: ['none', 'highSchool', 'bachelors', 'masters', 'phd', 'folk-high-school', 'vocational-education'],
    },
    experienceLevel: {
      description: '',
      allowEmpty: false,
      required: true,
      type: 'string',
      enum: [
        'none',
        'intern',
        'entryLevel',
        'associate',
        'midSeniorLevel',
        'executive',
        'director',
      ],
    },
    industries: {
      description: '',
      allowEmpty: false,
      required: true,
      type: 'array',
      properties: {
        type: 'string',
        allowEmpty: false,
      },
    },
    skills: {
      description: 'Select at least three skills',
      allowEmpty: true,
      required: true,
      type: 'array',
      items: {
        type: 'object',
        allowEmpty: 'false',
        properties: {
          id: {
            required: true,
            allowEmpty: false,
            type: 'string',
          },
          level: {
            required: true,
            allowEmpty: false,
            type: 'integer',
            minimum: 1,
            maximum: 5,
          },
          en: {
            required: true,
            allowEmpty: false,
            type: 'string'
          },
          sv: {
            required: true,
            allowEmpty: false,
            type: 'string'
          },
          de: {
            required: true,
            allowEmpty: false,
            type: 'string'
          },
          nextLayer: {
            required: true,
            allowEmpty: false,
            type: 'string'
          },
          type: {
            required: true,
            allowEmpty: false,
            type: 'string'
          },
        },
      },
    },

    freeText: {
      type: 'string',
    },
    status: {
      description: '',
      allowEmpty: false,
      required: true,
      type: 'string',
      enum: ['draft', 'template', 'published']
    },
    fetchedFrom: {
      type: 'string',
    },
    numberOfVacancies: {
      type: 'integer',
      required: true,
      allowEmpty: false,
      min:1
    },
    employmentType: {
      type: 'string',
      required: true,
    },
    scopeOfWork: {
      type: 'object',
      properties: {
        min: {
          type: 'integer',
          minimum: 0,
          maximum: 100,
        },
        max: {
          type: 'integer',
          minimum: 0,
          maximum: 100,
        },
      },
    },

    salaryType: {
      type: 'string',
      required: true,
    },

    duration: {
      type: 'string',
      required: true,
    },

    workingHoursType: {
      type: 'string',
      required: true,
    },
    occupationField: {
      type: 'object',
      properties: {
        jobTechId: {
          type: 'string',
          required: true
        }
      },
      required: true,
      allowEmpty: false
    },
    coordinates: {
      type: 'array',
      minItems: 2,
      maxItems: 2,
      items: {
        type: 'number',
        allowEmpty: false,
      },
    },
    reference: {
      type: 'string',
    },
    remoteWork: {
      type: 'string',
      enum: ['flexible', 'fullyRemote', 'fullyOnSite'],
    },
    isFromDashboard: {
      type: 'boolean',
    },
    isPremium: {
      type: 'boolean',
    },
  },
};
