
//move to validation/index.js

const transformToErrorDataStructure = (errors: any[]) => {
    let newErrors: any[] = [];
  
    errors.forEach((error) => {
      if (!newErrors[error.field || error.property])
        newErrors[(error.field || error.property)] = {
          field: error.field || error.property,
          message: error.message,
        };
    });
  
    return newErrors;
  }
export {
    transformToErrorDataStructure
}