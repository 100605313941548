import axios, { AxiosPromise, AxiosResponse } from "axios";
import { ICompany } from "./interface";

const developerToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3NMZXZlbCI6ImRldmVsb3BlciJ9.94708dCFQO0Skn2TuUIeINK_5Nogh036UxDKZTwABiI";



const companyService = {
  searchCompany: (organizationNumber: string) => {
    return new Promise<AxiosResponse>((resolve, reject) => {  
        axios
        .get(`company/organisation-number/${organizationNumber}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((resp: AxiosResponse) => resolve(resp))
        .catch((e) => reject(e))
      }
    )
  },
  createCompany: (data: ICompany) => {
    return new Promise<AxiosResponse>((resolve, reject) =>
      axios
        .post("company", data, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((resp: AxiosResponse) => {
          resolve(resp)
        })
        .catch((e) => reject(e))
    );
  },
  getCompanyById: (id: string) => {
    return new Promise<AxiosResponse>((resolve, reject) =>
      axios
        .get(`company/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((resp: AxiosResponse) => {
          resolve(resp)
        })
        .catch((e) => reject(e))
    );
  },
  updateCompany: (changes: ICompany) => {
    return new Promise<AxiosResponse>((resolve, reject) =>
      axios
        .patch("company", changes, {
          headers: {
            "Content-Type": "application/json",
           // "authorization": `Bearer ${developerToken}`
          },
        })
        .then((resp: AxiosResponse) => {
          resolve(resp)
        })
        .catch((e) => reject(e))
    );
  },
  uploadCompanyFile: (file: any) => {
    return new Promise<AxiosResponse>((resolve, reject) =>
      axios
        .patch("company/uploadFile", file, {
          headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${developerToken}`
          },
        })
        .then((resp: AxiosResponse) => {
          resolve(resp)
        })
        .catch((e) => reject(e))
    );
  },
};

export default companyService;
