
import mixpanel from 'mixpanel-browser';
import tagManager from 'react-gtm-module';

mixpanel.init('fe0d5207e5a4acb038310dd1be3516c9');
const tagManagerArgs = {
    gtmId: 'GTM-PTPKPLF'
}

//tagManager.initialize(tagManagerArgs);

let isProd = process.env.NODE_ENV === 'production';

let gaActions = {
    login: (props) => {
        const tagManagerArgs = {
            dataLayer: {
                event : 'login',
                loginMethod: 'email',
                userId: props.id
            },
        }
        
        //tagManager.dataLayer(tagManagerArgs);
    }
}
let actions = {
    setUserIdentity: (id) => {
      if (isProd) mixpanel.identify(id);
      else console.log('mixpanel === ', id);
    },
    setUserProfile: (props) => {
        if (isProd) mixpanel.people.set(props);
        else console.log('mixpanel === ', props)
    },
    logEvent: (eventName, payload = {}) => {
        if(isProd) 
        {
            mixpanel.track(eventName, payload);
        }
        else 
        {
            console.log('mixpanel === ', eventName, payload);
        }
    }
};

export const analyticsLogger = actions;
export const gtagManager = gaActions;