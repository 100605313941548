
const workingHoursType = {
      fullTime: {
          sv: 'Heltid',
          en: 'Full-time',
          de: 'Vollzeit',
          jobTechId: '6YE1_gAC_R2G'
      },
      partTime: {
          sv: 'Deltid',
          en: 'Part-time',
          de: 'Teilzeit',
          jobTechId: '947z_JGS_Uk2'
      }
  };
  const employmentType = {
      // deprecatedPermanentEmployment: {
      //   sv: 'Vanlig anställning',
      //   en: 'Permanent employment',
      //   de: 'Unbefristete Beschäftigung',
      //   jobTechId: 'PFZr_Syz_cUq',
      // },
      // deprecatedSeasonalEmployment: {
      //   sv: 'Sommarjobb / feriejobb',
      //   en: 'Seasonal employment',
      //   de: 'Saisonarbeit/Ferienjob',
      //   jobTechId: 'Jh8f_q9J_pbJ',
      // },
      permanentEmployment: {
          sv: 'Tillsvidareanställning (inkl. eventuell provanställning)',
          en: 'Permanent employment (incl. potential probationary employment',
          de: 'Unbefristetes Arbeitsverhältnis (inkl. möglicher Probezeit)',
          jobTechId: 'kpPX_CNN_gDU',
      },
      temporaryEmployment: {
          sv: 'Tidsbegränsad anställning',
          en: 'Temporary employment',
          de: 'Befristete Beschäftigung',
          jobTechId: 'sTu5_NBQ_udq',
      },
      substituteEmployment: {
          sv: 'Vikariat',
          en: 'Temporary substitute',
          de: 'Vertretung/Aushilfe',
          jobTechId: 'gro4_cWF_6D7',
      },
      onDemandEmployment: {
          sv: 'Behovsanställning',
          en: 'On demand employment',
          de: 'Beschäftigung auf Abruf',
          jobTechId: '1paU_aCR_nGn',
      },
      seasonalEmployment: {
          sv: 'Säsongsanställning',
          en: 'Seasonal employment',
          de: 'Saisonarbeit',
          jobTechId: 'EBhX_Qm2_8eX',
      }
  };
  const salaryType = {
      fixedMonthlyWeeklyHourlySalary: {
          sv: 'Fast månads- vecko- eller timlön',
          en: 'Fixed monthly, weekly, or hourly salary',
          de: 'Festes monatliches, wöchentliches oder stündliches Gehalt',
          jobTechId: 'oG8G_9cW_nRf',
      },
      variablePiecerateCommissionSalary: {
          sv: 'Rörlig ackords- eller provisionslön',
          en: 'Variable piece rate or commission salary',
          de: 'Variabler Akkord- oder Provisionslohn',
          jobTechId: 'vVtj_qm6_GQu',
      },
      fixedAndVariableSalary: {
          sv: 'Fast och rörlig lön',
          en: 'Fixed and variable salary',
          de: 'Festes und variables Gehalt',
          jobTechId: 'asrX_9Df_ukn',
      },
      unpaid: {
        sv: 'Unpaid',
        en: 'Unpaid',
        de: 'Unpaid',
        jobTechId: 'dashboard_unpaid_id',
      }
  };
  const durationType = {
      // deprecatedSixMonthsOrMore: {
      //   sv: '6 månader eller längre',
      //   en: '6 months or more',
      //   de: '6 Monate oder länger',
      //   jobTechId: 'QUd_4qe_NDT',
      // },
      permanent: {
          sv: 'Tills vidare',
          en: 'Permanent',
          de: 'Unbefristet',
          jobTechId: 'a7uU_j21_mkL',
      },
      twelveMonthsTwoYears: {
          sv: '12 månader - upp till 2 år',
          en: '12 months to 2 years',
          de: '12 Monate bis 2 Jahre',
          jobTechId: '9RGe_UxD_FZw',
      },
      sixMonthsTwelveMonths: {
          sv: '6 månader – upp till 12 månader',
          en: '6 months to 12 months',
          de: '6 bis 12 Monate',
          jobTechId: 'gJRb_akA_95y',
      },
      threeMonthsSixMonths: {
          sv: '3 månader – upp till 6 månader',
          en: '3 months to 6 months',
          de: '3 bis 6 Monate',
          jobTechId: 'Xj7x_7yZ_jEn',
      },
      elevenDaysThreeMonths: {
          sv: '11 dagar - upp till 3 månader',
          en: '11 days to 3 months',
          de: '11 Tage bis 3 Monate',
          jobTechId: 'Sy9J_aRd_Alx',
      },
      maxTenDays: {
          sv: 'Upp till 10 dagar',
          en: 'Up to 10 days',
          de: 'Bis zu 10 Tage',
          jobTechId: 'cAQ8_TpB_Tdv',
      }
  };
  const remoteWorkTypes = {
      flexible: {
          sv: 'Flexibel',
          en: 'Flexible',
          de: 'Flexibel',
      },
      fullyRemote: {
          sv: 'Distansarbete',
          en: 'Remote work',
          de: 'Fernarbeit (remote)',
      },
      fullyOnSite: {
          sv: 'På plats',
          en: 'On site',
          de: 'Vor Ort',
      }
  };
  
  const occupations = [{
      id: "X82t_awd_Qyc",
      sv: "Administration, ekonomi, juridik",
      en: "Administration, economics, legal",
      de: "Verwaltung, Wirtschaft, Rechtswesen",
      // source: require("../../assets/images/X82tawdQyc.png")
  }, {
      id: "j7Cq_ZJe_GkT",
      sv: "Bygg och anläggning",
      en: "Building and construction",
      de: "Bau und Konstruktion",
      // source: require("../../assets/images/j7CqZJeGkT.png")
  }, {
      id: "bh3H_Y3h_5eD",
      sv: "Chefer och verksamhetsledare",
      en: "Managers and executives",
      de: "Manager und Führungskräfte",
      // source: require("../../assets/images/bh3HY3h5eD.png")
  }, {
      id: "apaJ_2ja_LuF",
      sv: "Data, IT",
      en: "Data, IT",
      de: "Daten, IT",
      // source: require("../../assets/images/apaJ2jaLuF.png")
  }, {
      id: "RPTn_bxG_ExZ",
      sv: "Försäljning, inköp, marknadsföring",
      en: "Sales, purchase, marketing",
      de: "Vertrieb, Einkauf, Marketing",
      // source: require("../../assets/images/RPTnbxGExZ.png")
  }, {
      id: "NYW6_mP6_vwf",
      sv: "Hälso- och sjukvård",
      en: "Health and medical care",
      de: "Gesundheit und med. Versorgung",
      // source: require("../../assets/images/NYW6mP6vwf.png")
  }, {
      id: "PaxQ_o1G_wWH",
      sv: "Hantverk",
      en: "Crafts",
      de: "Handwerk",
      // source: require("../../assets/images/PaxQo1GwWH.png")
  }, {
      id: "ScKy_FHB_7wT",
      sv: "Hotell, restaurang, storhushåll",
      en: "Hotel, restaurant, gastronomy",
      de: "Hotel, Restaurant, Gastronomie",
      // source: require("../../assets/images/ScKyFHB7wT.png")
  }, {
      id: "wTEr_CBC_bqh",
      sv: "Industriell tillverkning",
      en: "Industrial manufacturing",
      de: "Industrielle Fertigung",
      // source: require("../../assets/images/wTErCBCbqh.png")
  }, {
      id: "yhCP_AqT_tns",
      sv: "Installation, drift, underhåll",
      en: "Installation, operation, maintenance",
      de: "Installation, Betrieb, Wartung",
      // source: require("../../assets/images/yhCPAqTtns.png")
  }, {
      id: "Uuf1_GMh_Uvw",
      sv: "Kropps- och skönhetsvård",
      en: "Body and beauty care",
      de: "Körper- und Schönheitspflege",
      // source: require("../../assets/images/Uuf1GMhUvw.png")
  }, {
      id: "9puE_nYg_crq",
      sv: "Kultur, media, design",
      en: "Culture, media, design",
      de: "Kultur, Medien, Design",
      // source: require("../../assets/images/9puEnYgcrq.png")
  }, {
      id: "bH5L_uXD_ZAX",
      sv: "Militära yrken",
      en: "Military professions",
      de: "Militärische Berufe",
      // source: require("../../assets/images/bH5LuXDZAX.png")
  }, {
      id: "VuuL_7CH_adj",
      sv: "Naturbruk",
      en: "Agriculture",
      de: "Agrarwirtschaft",
      // source: require("../../assets/images/VuuL7CHadj.png")
  }, {
      id: "kJeN_wmw_9wX",
      sv: "Naturvetenskap",
      en: "Natural Sciences",
      de: "Naturwissenschaften",
      // source: require("../../assets/images/kJeNwmw9wX.png")
  }, {
      id: "MVqp_eS8_kDZ",
      sv: "Pedagogik",
      en: "Pedagogy",
      de: "Pädagogik",
      // source: require("../../assets/images/MVqpeS8kDZ.png")
  }, {
      id: "E7hm_BLq_fqZ",
      sv: "Säkerhet och bevakning",
      en: "Security and surveillance",
      de: "Sicherheit und Überwachung",
      // source: require("../../assets/images/E7hmBLqfqZ.png")
  }, {
      id: "whao_Q6A_ScE",
      sv: "Sanering och renhållning",
      en: "Sanitation and cleaning",
      de: "Sanitär und Reinigung",
      // source: require("../../assets/images/whaoQ6AScE.png")
  }, {
      id: "ASGV_zcE_bWf",
      sv: "Transport, distribution, lager",
      en: "Transport, distribution, storage",
      de: "Transport, Distribution, Lager",
      // source: require("../../assets/images/ASGVzcEbWf.png")
  }, {
      id: "GazW_2TU_kJw",
      sv: "Yrken med social inriktning",
      en: "Social professions",
      de: "Sozialberufe",
      // source: require("../../assets/images/GazW2TUkJw.png")
  }, {
      id: "6Hq3_tKo_V57",
      sv: "Yrken med teknisk inriktning",
      en: "Technical professions",
      de: "Technische Berufe",
      // source: require("../../assets/images/6Hq3tKoV57.png")
  }];
  
  
  const companyTypes = [{
          id: "Aktiebolag",
          sv: "Aktiebolag",
          en: "Aktiebolag (Swedish limited company)",
          de: "Aktiebolag (schwedische Aktiengesellschaft)"
      },
      {
          id: "Handelsbolag",
          sv: "Handelsbolag",
          en: "General partnership",
          de: "Handelsgesellschaft"
      },
      {
          id: "Kommanditbolag",
          sv: "Kommanditbolag",
          en: "Limited partnership",
          de: "Kommanditgesellschaft"
      },
      {
          id: "Ekonomisk förening",
          sv: "Ekonomisk förening",
          en: "Economic association",
          de: "Wirtschaftliche Vereinigung"
      },
      {
          id: "Kommun",
          sv: "Kommun",
          en: "Municipality",
          de: "Kommune"
      },
      {
          id: "Landsting",
          sv: "Landsting",
          en: "Regional council (landsting)",
          de: "Provinziallandtag (landsting)"
      },
      {
          id: "Statlig enhet",
          sv: "Statlig enhet",
          en: "Government unit",
          de: "Staatliche Behörde"
      },
      {
          id: "Kommunalförbund",
          sv: "Kommunalförbund",
          en: "Regional federation",
          de: "Kommunalverbund"
      },
      {
          id: "Europabolag (SE)",
          sv: "Europabolag",
          en: "Societas Europaea (SE)",
          de: "Europäische Gesellschaft (SE)"
      },
      {
          id: "Enskild näringsverksamhet",
          sv: "Enskild näringsverksamhet",
          en: "Sole proprietorship",
          de: "Einzelunternehmen"
      },
      {
          id: "Enkelt bolag",
          sv: "Enkelt bolag",
          en: "Partnership",
          de: "Personengesellschaft"
      },
      {
          id: "Partrederier",
          sv: "Partrederi",
          en: "Partner shipping company",
          de: "Partner-Schifffahrtsgesellschaft"
      },
      {
          id: "Gruvbolag",
          sv: "Gruvbolag",
          en: "Mining company",
          de: "Bergbaugesellschaft"
      },
      {
          id: "Bankaktiebolag",
          sv: "Bankaktiebolag",
          en: "Bankaktiebolag (bank company)",
          de: "Bankaktiengesellschaft"
      },
      {
          id: "Försäkringsaktiebolag",
          sv: "Försäkringsaktiebolag",
          en: "Insurance company",
          de: "Versicherungsgesellschaft"
      },
      {
          id: "Bostadsrättsförening",
          sv: "Bostadsrättsförening",
          en: "Housing company",
          de: "Wohnungsbaugesellschaft"
      },
      {
          id: "Kooperativ Hyresrättsförening",
          sv: "Kooperativ Hyresrättsförening",
          en: "Cooperative rental association",
          de: "Genossenschaftlicher Mietverband"
      },
      {
          id: "Europakooperativ, EGTS och Eric-konsortier",
          sv: "Europakooperativ (SCE), EGTS eller ERIC",
          en: "Societas cooperativa Europaea (SCE), EGTC or ERIC",
          de: "Europäische Genossenschaft (SCE), EVTZ oder ERIC"
      },
      {
          id: "Ideell förening",
          sv: "Ideell förening",
          en: "Non-profit organization",
          de: "NPO oder gemeinnütziger Verein"
      },
      {
          id: "Samfälligheter",
          sv: "Samfällighet",
          en: "Community",
          de: "Gemeinschaft"
      },
      {
          id: "Registrerat trossamfund",
          sv: "Registrerat trossamfund",
          en: "Registered religious community",
          de: "Eingetragene Religionsgemeinschaft"
      },
      {
          id: "Familjestiftelse",
          sv: "Familjestiftelse",
          en: "Family foundation",
          de: "Familienstiftung"
      },
      {
          id: "Övriga stiftelser och fonder",
          sv: "Övriga stiftelser och fonder",
          en: "Other foundations and trusts",
          de: "Andere Stiftungen und Kassen"
      },
      {
          id: "Allmänn försäkringskassa",
          sv: "Allmänn försäkringskassa",
          en: "Public social security fund",
          de: "Öffentliche Sozialversicherungskasse"
      },
      {
          id: "Offentlig korporation eller anstalt",
          sv: "Offentlig korporation eller anstalt",
          en: "Public corporation or institution",
          de: "Öffentliche Körperschaft oder Einrichtung"
      },
      {
          id: "Hypoteksförening",
          sv: "Hypoteksförening",
          en: "Mortgage association",
          de: "Hypothekenverband"
      },
      {
          id: "Regionell statlig myndighet",
          sv: "Regionell statlig myndighet",
          en: "Regional government agency",
          de: "Regionale Regierungsbehörde"
      },
      {
          id: "Ömsesidigt försäkringsbolag",
          sv: "Ömsesidigt försäkringsbolag",
          en: "Mutual insurance company",
          de: "Versicherungsverein auf Gegenseitigkeit"
      },
      {
          id: "Sparbank",
          sv: "Sparbank",
          en: "Savings bank",
          de: "Sparkasse"
      },
      {
          id: "Understöds-eller Försäkringsförening",
          sv: "Understöds- eller försäkringsförening",
          en: "Support or sponsor association",
          de: "Stütz- oder Förderverein"
      },
      {
          id: "Arbetslöshetskassa",
          sv: "Arbetslöshetskassa",
          en: "Unemployment insurance fund",
          de: "Arbeitslosenkasse"
      },
      {
          id: "Utländsk juridisk person",
          sv: "Utländsk juridisk person",
          en: "Foreign juridical person",
          de: "Ausländische juristische Person"
      },
      {
          id: "Inte applicerbart",
          sv: "Inte applicerbart",
          en: "Not applicable",
          de: "Nichts zutreffend"
      }
  ]
  
  const industries = [{
          id: "A",
          sv: "A Jordbruk, skogsbruk och fiske",
          en: "A Agriculture, forestry and fishing",
          de: "A Landwirtschaft, Forstwirtschaft und Fischerei"
      },
      {
          id: "B",
          sv: "B Utvinning av mineral",
          en: "B Mining and quarrying",
          de: "B Bergbau und Rohstoffgewinnung"
      },
      {
          id: "C",
          sv: "C Tillverkning",
          en: "C Manufacturing",
          de: "C Fertigung"
      },
      {
          id: "D",
          sv: "D Försörjning av el, gas, värme och kyla",
          en: "D Electricity, gas, steam and air conditioning supply",
          de: "D Versorgung mit Elektrizität, Gas, Wärme und Klimaanlagen"
      },
      {
          id: "E",
          sv: "E Vattenförsörjning; avloppsrening, avfallshantering och sanering",
          en: "E Water supply; sewerage, waste management and remediation activities",
          de: "E Wasserversorgung; Abwasser-, Abfallentsorgung und Sanierungstätigkeiten"
      },
      {
          id: "F",
          sv: "F Byggverksamhet",
          en: "F Construction",
          de: "F Bauwesen"
      },
      {
          id: "G",
          sv: "G Handel; reparation av motorfordon och motorcyklar",
          en: "G Wholesale and retail trade; repair of motor vehicles and motorcycles",
          de: "G Handel; Instandhaltung und Reparatur von Kraftwagen und Krafträdern"
      },
      {
          id: "H",
          sv: "H Transport och magasinering",
          en: "H Transportation and storage",
          de: "H Transport und Lagerung"
      },
      {
          id: "I",
          sv: "I Hotell- och restaurangverksamhet",
          en: "I Accommodation and food service activities",
          de: "I Beherbergung und Gastronomie"
      },
      {
          id: "J",
          sv: "J Informations- och kommunikationsverksamhet",
          en: "J Information and communication",
          de: "J Information und Kommunikation"
      },
      {
          id: "K",
          sv: "K Finans- och försäkringsverksamhet",
          en: "K Financial and insurance activities",
          de: "K Finanz- und Versicherungsdienstleistungen"
      },
      {
          id: "L",
          sv: "L Fastighetsverksamhet",
          en: "L Real estate activities",
          de: "L Immobilienaktivitäten"
      },
      {
          id: "M",
          sv: "M Verksamhet inom juridik, ekonomi, vetenskap och teknik",
          en: "M Professional, scientific and technical activities",
          de: "M Dienstleistung innerhalb Rechtswesen, Wirtschaft, Wissenschaft und Technologie"
      },
      {
          id: "N",
          sv: "N Uthyrning, fastighetsservice, resetjänster och andra stödtjänster",
          en: "N Rental, real estate services, travel services and other support services",
          de: "N Vermietung, Immobilien-, Reise- und andere Service-Dienstleistungen"
      },
      {
          id: "O",
          sv: "O Offentlig förvaltning och försvar; obligatorisk socialförsäkring",
          en: "O Public administration and defence; compulsory social security",
          de: "O Öffentliche Verwaltung und Verteidigung; gesetzliche Sozialversicherung"
      },
      {
          id: "P",
          sv: "P Utbildning",
          en: "P Education",
          de: "P Bildungswesen"
      },
      {
          id: "Q",
          sv: "Q Vård och omsorg; sociala tjänster",
          en: "Q Human health and social work activities",
          de: "Q Gesundheits- und Sozialwesen"
      },
      {
          id: "R",
          sv: "R Kultur, nöje och fritid",
          en: "R Arts, entertainment and recreation",
          de: "R Kunst, Unterhaltung und Freizeit"
      },
      {
          id: "S",
          sv: "S Annan serviceverksamhet",
          en: "S Other service activities",
          de: "S Sonstige Dienstleistungsaktivitäten"
      },
      {
          id: "T",
          sv: "T Förvärvsarbete i hushåll; hushållens produktion av diverse varor och tjänster för eget bruk",
          en: "T Activities of households as employers; undifferentiated goods- and sevices-producing activities of households for own use",
          de: "T Tätigkeiten von privaten Haushalten als Arbeitgeber; Herstellung von Waren und Dienstleistungen durch private Haushalte für den Eigenbedarf"
      },
      {
          id: "U",
          sv: "U Verksamhet vid internationella organisationer, utländska ambassader o.d.",
          en: "U Activities of international organisations and bodies",
          de: "U Aktivitäten von internationalen Organisationen, ausländischen Botschaften usw."
      }
  ]
  
  const companySizes = [{
          id: "Enmansföretag",
          sv: "Enmansföretag (0)",
          en: "One-person company (0)",
          de: "Ein-Person-Unternehmen (0)"
      },
      {
          id: "Mikroföretag",
          sv: "Mikroföretag (1-9)",
          en: "Micro-enterprise (1-9)",
          de: "Kleinstunternehmen (1-9)"
      },
      {
          id: "Litet företag",
          sv: "Små företag (10-49)",
          en: "Small enterprise (10-49)",
          de: "Kleinunternehmen (10-49)"
      },
      {
          id: "Medelstort företag",
          sv: "Medelstora företag (50-249)",
          en: "Medium-sized enterprise (50-249)",
          de: "Mittelgroßes Unternehmen (50-249)"
      },
      {
          id: "Stort företag",
          sv: "Stort företag > 250",
          en: "Large enterprise > 250",
          de: "Großunternehmen > 250"
      },
  ]
  
  const jobStatusMessages = {
      CLOSED: {
          title: {
              id: "Closed",
              sv: "Stängt",
              en: "Closed",
              de: "Geschlossen"
          },
          description: {
              sv: "Stängd för ansökningar",
              en: "Closed for applications",
              de: "Geschlossen für Bewerbungen"
          }
      },
      PUBLISHED: {
          title: {
              id: "Published",
              sv: "Publicerad",
              en: "Published",
              de: "Veröffentlicht"
          },
          description: {
              sv: "Öppet för ansökningar",
              en: "Open for applications",
              de: "Offen für Bewerbungen"
          }
      },
      DRAFT: {
          title: {
              id: "Draft",
              sv: "Utkast",
              en: "Draft",
              de: "Entwurf"
          },
          description: {
              sv: "Inte publicerad ännu",
              en: "Not published yet",
              de: "Noch nicht veröffentlicht"
          }
      },
      TEMPLATE: {
          title: {
              id: "Template",
              sv: "Mall",
              en: "Template",
              de: "Vorlage"
          },
          description: {
              sv: "Mall",
              en: "Template",
              de: "Vorlage"
          }
      },
      ARCHIVED: {
          title: {
              id: "Archived",
              sv: "Borttagen",
              en: "Removed",
              de: "Entfernt"
          },
          description: {
              sv: "Borttagen",
              en: "Archived",
              de: "Entfernt"
          }
      }
  }
  
  const workExperiences = [{
          id: "none",
          sv: "Ingen erfarenhet",
          en: "No experience",
          de: "Keine Erfahrung"
      },
      {
          id: "intern",
          sv: "Praktikant (mindre än 1 år)",
          en: "Intern (Less than 1 year)",
          de: "Praktikant (weniger als 1 Jahr)"
      },
      {
          id: "entryLevel",
          sv: "Instegsnivå (1-2 år)",
          en: "Entry level (1-2 years)",
          de: "Jobeinsteiger (1-2 Jahre)"
      },
      {
          id: "associate",
          sv: "Associate nivå (2-5 år)",
          en: "Associate (2-5 years)",
          de: "Associate-Level (2-5 Jahre)"
      },
      {
          id: "midSeniorLevel",
          sv: "Mid-Senior nivå (5-10 år)",
          en: "Mid-Senior (5-10 years)",
          de: "Mid-Senior-Level (5-10 Jahre)"
      },
      {
          id: "executive",
          sv: "Executive nivå (10-20 år)",
          en: "Executive (10-20 years)",
          de: "Executive-Level (10-20 Jahre)"
      },
      {
          id: "director",
          sv: "Director nivå (mer än 20 år)",
          en: "Director (more than 20 years)",
          de: "Director-Level (mehr als 20 Jahre)"
      }
  ]
  
  const qualifications = [{
          id: "none",
          sv: "Inte ett krav",
          en: "Not a requirement",
          de: "Keine Voraussetzung"
      },
      {
          id: "highSchool",
          sv: "Gymnasieskola",
          en: "High School",
          de: "Hochschulreife"
      },
      {
        id: "folk-high-school",
        sv: "Folkhögskola",
        en: "Folk high school",
        de: "Volkshochschule"
      },
      {
        id: "vocational-education",
        sv: "Yrkesutbildning",
        en: "Vocational education",
        de: "Berufsausbildung"
      },
      {
          id: "bachelors",
          sv: "Kandidatexamen eller motsvarande",
          en: "Bachelor or equivalent",
          de: "Bachelor oder gleichwertig"
      },
      {
          id: "masters",
          sv: "Master eller motsvarande",
          en: "Master or equivalent",
          de: "Master oder gleichwertig"
      },
      {
          id: "phd",
          sv: "Doktorsexamen",
          en: "PhD degree",
          de: "Doktorgrad"
      }
  ]
  
  export {
      workingHoursType,
      durationType,
      employmentType,
      salaryType,
      remoteWorkTypes,
      occupations,
      companyTypes,
      industries,
      companySizes,
      jobStatusMessages,
      workExperiences,
      qualifications
  };