import axios, { AxiosResponse } from "axios";

const skillService = {
  getAllSkills: (filters = null) => {
    const buildFilterPath = (filters) => {
      if (!filters) return '';
    
      const resultPath = filters.filter(x => x).reduce((finalPath, path, index) => {
        if (index > 0) finalPath += `+${path}`;
        else finalPath = path;
    
        return finalPath;
      }, '');
      return resultPath;
    }

    return new Promise<AxiosResponse>((resolve, reject) =>
      axios
        .get(`skill/${buildFilterPath(filters)}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((resp: AxiosResponse) => resolve(resp))
        .catch((e) => reject(e))
    );
  }
};

export default skillService;
