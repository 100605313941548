import 'antd/dist/antd.css';
import { Menu } from 'antd';
import { useEffect, useState } from 'react';
import skillService from 'services/skills';
import { handleRequest } from 'services/api-handler';
import Strings from 'localization/translation';


const App = ({ skills, onSkillSelected }) => {

  const [subcategories, setSubcategories] = useState([]);
  const [selectedSkills, setSkills] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    const categories = skills?.filter(item => !item.nextLayer);
    setCategories(categories);
  }, [])

  const onMenuItemClick = async (id, type) => {
      
    if (type === 'skill_clicked') {
      const selectedSkill = skills.find(x => x.id == id);
      onSkillSelected(selectedSkill)
    } else {
        if (type === 'category_clicked'){
          const category = skills.find(x => x.id == id);
          const path = [category?.en]
          setFilters(path)
          const [error, data] = await handleRequest(skillService.getAllSkills(path));

          const subcategories = data.filter(item => item.nextLayer == null && item.previousLayer == null);
          setSubcategories(subcategories);
        }
      if (type === 'subcategory_clicked'){
          const subcategory = subcategories.find(x => x.id == id);

          const path = [filters[0], subcategory?.en]
          const [error, data] = await handleRequest(skillService.getAllSkills(path));
         
          const filterdSkills = data.filter(item => !item.nextLayer && item.previousLayer);
          setSkills(filterdSkills);
      }
  }
}

const formatSkillsToMenuItems = (skills) => {
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type
    };
  }
  return [
    ...skills?.map(item => {
      return getItem(item[Strings.getLanguage()], item.id, null, null)
    })
  ];
}
return (
  <div className="row" style={{ position: 'relative'}}>
    <div className="col-lg-4">
     { categories.length > 0 && <Menu
        mode="inline"
        onClick={(e) => onMenuItemClick(e.key, 'category_clicked')}
        style={{
          width: 256,
        }}
        items={ formatSkillsToMenuItems(categories) }
      /> }
    </div>
    <div className="col-lg-4">
     {subcategories.length > 0 && <Menu
        mode="inline"
        onClick={(e) => onMenuItemClick(e.key, 'subcategory_clicked')}
        style={{
          width: 256,
        }}
        items={formatSkillsToMenuItems(subcategories)}
      />}
    </div>
    <div className="col-lg-4">
      { selectedSkills.length > 0 && <Menu
        mode="inline"
        onClick={(e) => onMenuItemClick(e.key, 'skill_clicked')}
        style={{
          width: 256,
        }}
        items={formatSkillsToMenuItems(selectedSkills)}
      /> }
    </div>
  </div>
);
};

export default App;