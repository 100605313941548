import { useEffect, useState } from 'react';
import {
    Channel,
    ChannelHeader,
    ChannelList, Chat, MessageInput,
    MessageList,
    Thread, Window
} from 'stream-chat-react';

import { useClient } from '../../hooks/useChatClient';
import 'stream-chat-react/dist/css/v2/index.css';


const options = {
    limit: 20,
    messages_limit: 30,
    state: true,
    watch: true,
  };
  const sort = { last_message_at: -1 };
  
  export const ChatScreen = ({companyAccount, applicantAccount}) => {
    const [filters, setFilters] = useState(null);
    const [channel, setChannel] = useState(null);
    const [channelLoadError, setChannelLoadError] = useState(null);

    const { chatClient } = useClient({
      currentAccount: companyAccount
    });
  
    useEffect(() => {
      if (chatClient) {
        try
        {
          const channel = chatClient.channel('messaging',
              {
                members: [companyAccount.id, applicantAccount.id]
              });
          setChannel(channel);
          setChannelLoadError(null);
          setFilters({
            type: 'messaging',
            members: {
              $in: [companyAccount.id, applicantAccount.id],
            },
          });
       }
      catch(error){
        //setChannel(channel);
        setChannelLoadError(error);
      }
      }
    }, [chatClient]);
    if(!chatClient) return (
        <div>Loading...</div>
    )
    if(channelLoadError)return (
      <div>Loading...</div>
     )

    return (
      <div></div>
        // <Chat initialNavOpen client={chatClient} theme='str-chat__theme-light'>
        //   {channel ? (
        //     <Channel channel={channel}>
        //        <Window >
        //         <ChannelHeader image={companyAccount.image}/>
        //         <MessageList />
        //         <MessageInput />
        //        </Window>
        //        <Thread />
        //     </Channel>
        //   ) : (
        //     <ChannelList
        //       onSelect={setChannel}
        //       filters={filters}
        //       maxUnreadCount={99}
        //       options={options}
        //       sort={sort}
        //       LoadingErrorIndicator={() => (<div></div>)}
        //     />
        //   )}
        // </Chat>
    );
  }
