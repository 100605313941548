import useAppLanguage from "hooks/useLanguage";
import Strings from "localization/translation"

const LANGUAGES = [{ISOcode: "sv", name: "Svenska"}, {ISOcode: "en", name: "English"}, {ISOcode: "de",  name: "Deutsch"} ]

function LanguageBar(){
    const { setAppLanguage } = useAppLanguage();

    const changeLanguageTo = (language) => {
        setAppLanguage(language);
        Strings.setLanguage(language);
        window.location.reload()
    }

    return (
        <>
             <div className="dropdown">
                            <span className="fi fi-gr"></span> <span className="fi fi-gr fis"></span>
                            <button className="notification-btn" type="button" id="notificationDropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="la-duotone la la-language"></i>
                            </button>
                            <div className="dropdown-menu" aria-labelledby="notificationDropdownMenu">
                                <div className="mess-dropdown">
                                    <div className="mess__body">
                                        {
                                            LANGUAGES.map((item) => {
                                                return (
                                                    <a href="#!" key={item.ISOcode} className="d-block" onClick={() => changeLanguageTo(item.ISOcode)}>
                                                        <div className="mess__item">
                                                            <div className="content">
                                                                <p className="text">{ item.name }</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                )
                                            })
                                        }
                                       
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                                                   
        </>
    )
}

export default LanguageBar;