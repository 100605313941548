import Strings from 'localization/translation';
import AuthLayout from 'pages/layouts/PublicLayout';
import  { useState } from 'react';
import accountService from 'services/accounts';
import { handleRequest } from 'services/api-handler';
import { NotificationType, showNotificationWithIcon } from 'services/notifications';

function ForgotPassword () {

     const [email, setEmail] = useState();
     
     const requestResetPassword = async (email) => {
        if(!email) return;
        const [requestError, requestSuccess] = await handleRequest(accountService.resetPassword(email))

        if(requestError) {
            showNotificationWithIcon(Strings.errors.passwordReset, requestError.message, NotificationType.ERROR);
            return;
        }
        const emailSentToMessage = Strings.success.emailSentTo.replace("${email}", email);
        showNotificationWithIcon(Strings.success.passwordRequestSent, emailSentToMessage, NotificationType.SUCCESS)
     }

        return (
           <>
            <AuthLayout>
                <div className="row">
                    <div className="col-lg-12">
                    <div className="breadcrumb-content d-flex flex-wrap justify-content-between align-items-center">
                        <div className="section-heading">
                        <h2 className="sec__title line-height-45">
                            { Strings.breadcrumbs.passwordReset }
                        </h2>
                        </div>
                        <ul className="list-items d-flex align-items-center">
                        <li className="active__list-item">
                            <a href="#!">{ Strings.breadcrumbs.accounts }</a>
                        </li>
                        <li className="active__list-item">{ Strings.breadcrumbs.forgotPassword } </li>
                        </ul>
                    </div>
                    </div>
                </div>
                <section class="form-shared padding-top-100px padding-bottom-100px">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 mx-auto">
                                <div class="billing-form-item mb-0">
                                    <div class="billing-title-wrap">
                                        <h3 class="widget-title font-size-28">{ Strings.resetPasswordTitle }</h3>
                                        <p class="font-size-15">{ Strings.resetPasswordMessage }
                                            <a href="https://employchain.se" target="_blank" class="color-text"> { Strings.contactUs }</a>
                                        </p>
                                    </div>
                                    <div class="billing-content">
                                        <div class="contact-form-action">
                                            <form method="post">
                                                <div class="input-box">
                                                    <label class="label-text">{ Strings.forms.email }</label>
                                                    <div class="form-group">
                                                        <span class="la la-envelope-o form-icon"></span>
                                                        <input class="form-control" type="email" name="text" placeholder={`${ Strings.forms.email }`}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="btn-box margin-top-20px margin-bottom-20px">
                                                    <button class="theme-btn border-0" type="button" onClick={() => requestResetPassword(email) }>{ Strings.actions.resetPasswordButton }</button>
                                                </div>
                                                <p>
                                                    <a href="/login" class="color-text">{ Strings.loginButton } </a>
                                                    or
                                                    <a href="/signup" class="color-text"> { Strings.createAccountButton }</a>
                                                </p>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </AuthLayout>
    </>
)
}
export default ForgotPassword;